// TODO: Refactor to use multi-carousel 

import React, { Component, Fragment } from 'react';
import {
	TestimonialImg as Img,
	TestimonialAuthor as Author,
	TestimonialTitle as Title,
	TestimonialQuote as Quote,
	FlexStart,
	ValuePropsHR as HR,
	ValuePropsContainer as Container,
	TestimonialContainer,
	TestimonialBody as Body
} from '../../../Style';
import * as Sentry from '@sentry/browser';
import { supplyDropTestimonials as testimonials } from '../../../data/SupplyDropTestimonials';

export class SummaryTestimonials extends Component {
	constructor(props) {
		super(props);

		this.state = {

		}

		this.toggleFadeIn = this.toggleFadeIn.bind(this);
	}

	componentDidMount() {
		const { isMobile } = this.props;
		const { testimonialsMap } = this.state;

		if (!isMobile && !testimonialsMap)
			this.setTestimonialsMap()
	}

	componentDidUpdate() {
		const { isMobile } = this.props;
		const { testimonialsMap } = this.state;

		if (isMobile && this.interval)
			clearInterval(this.interval);

		if (!isMobile && !testimonialsMap)
			this.setTestimonialsMap()
	}

	componentWillUnmount() {
		const { isMobile } = this.props;

		if (isMobile && this.interval)
			clearInterval(this.interval);
	}

	setTestimonialsMap() {
		let i = 0;
		const testimonialsMap = {};
		testimonials.entries.forEach(item => {
			const { quote, src, name } = item;
			testimonialsMap[i] = (
				<div id={`testimonial-${i}`}>
					<Quote>{quote}</Quote>
					<FlexStart>
						<Img src={src} alt={name} />
						<Author>{name}</Author>
					</FlexStart>
				</div>
			)
			i++;
		})
		this.setState({ testimonialsMap })
		this.interval = setInterval(this.updateCurrentTestimonial.bind(this), 6000);
	}

	async updateCurrentTestimonial() {
		try {
			const { testimonialsMap, currentTestimonial } = this.state;

			if (testimonialsMap) {
				if (currentTestimonial === undefined || currentTestimonial === Object.values(testimonialsMap).length - 1)
					this.setState({ currentTestimonial: 0 });
				else
					this.setState({ currentTestimonial: currentTestimonial + 1 });

				this.toggleFadeIn()
			}
		} catch (err) {
			Sentry.captureException(err);
			console.error(err);
			clearInterval(this.interval);
		}
	}

	toggleFadeIn() {
		try {
			const element = document.getElementById('testimonial-wrapper')
			if (element) {
				element.classList.add('animated', 'fadeInRight');

				setTimeout(() => {
					element.classList.remove('animated', 'fadeInRight');
				}, 1000)
			}
		} catch (error) {
			console.error(error)
			Sentry.captureException(error);
			return { error };
		}
	}

	renderPredictionDesktop() {
		const { isMobile } = this.props;
		const { currentTestimonial, testimonialsMap } = this.state;

		if (!isMobile && currentTestimonial !== undefined) {
			return (
				<Container>
					<Title>{testimonials.title}</Title>
					<div id="testimonial-wrapper">
						{testimonialsMap[currentTestimonial]}
					</div>
				</Container>
			)
		}
	}

	renderPredictionMobile() {
		const { isMobile } = this.props;
		if (isMobile) {
			const body = testimonials.entries.slice(0, 3).map((item, index) => {
				const { src, quote, name } = item;
				return (
					<Fragment key={index}>
						<Body>
							<Quote>{quote}</Quote>
							<FlexStart>
								<Img src={src} alt={name} />
								<Author>{name}</Author>
							</FlexStart>
						</Body>
						<HR  />
					</Fragment>
				)
			})
			return (
				<Container as={TestimonialContainer}>
					<Title>{testimonials.title}</Title>
					{body}
				</Container>
			)
		}
	}

	render() {
		return (
			<Fragment>
				{this.renderPredictionMobile()}
				{/* {this.renderPredictionDesktop()} */}
			</Fragment>
		)
	}
}