import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import * as images from '../../assets';
import '../../Style/landing.css';
import {
    Footer,
    LandingNav,
    ProductDetail,
    DocModal,
    MetaTags,
    FAQ,
    ProductCheckmarkRow,
    TestimonialCardCarousel,
    BetterWay,
    LandingTwoUpSection,
    Hero,
    ValueProps
} from '../Common';
import {
    getLanding,
    getProductGroups,
    getFAQs,
    postProspect,
    marginForCountdown,
    getUser,
    parseSearchParams
} from '../../actions';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import Analytics from "../../analytics";
import { BrandsCarousel } from '../Common/Landing/BrandsCarousel';
import { Link } from 'react-router-dom';
import {
    LandingMain as Main,
    LandingContainer,
    LandingSection as Section,
    LandingSectionBody as SectionBody,
    LandingBundle as Bundle,
    LandingBundleImg as BundleImg,
    H2Amaranth as H2,
    LandingCTA as CTA,
    LandingFAQsContainer as FAQsContainer,
    LandingFAQsBody as FAQsBody,
    PageDivider,
    flexCenter,
    householdPatternedBackground,
    conversationalPatternedBackground,
    HomeHeroPattern,
    TextCenter
} from '../../Style';
import {
    bundles,
    campaigns,
    campaignHeaders,
    defaultZipcodes,
    getCouponCampaign,
    getRefCampaign,
    customerReferralCampaign
} from '../../data';
import { supplyDropTestimonials } from '../../data/SupplyDropTestimonials';
import { HowItWorksSection } from '../Common/Landing/HowItWorksSection';
import { Button } from '@material-ui/core'
import { Logger } from '../../helpers/Logger';
import { featureFlags } from '../../config';
const logger = new Logger();

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.handleKeydown = this.handleKeydown.bind(this);
        this.onGetStartedClick = this.onGetStartedClick.bind(this);
        this.onDashboardClick = this.onDashboardClick.bind(this)
        this.handleResize = this.handleResize.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
        this.setFromSearchParams = this.setFromSearchParams.bind(this);
        this.handlePath = this.handlePath.bind(this);
        this.handlePathAndSearch = this.handlePathAndSearch.bind(this);
        this.redirectFromUtmCampaign = this.redirectFromUtmCampaign.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    handleResize(e) {
        const { isMobile, isTablet, isTrueMobile } = this.state;
        if (window.innerWidth < 768 && !isMobile) {
            this.setState({ isMobile: true })
        }
        if (window.innerWidth >= 767 && isMobile) {
            this.setState({ isMobile: false })
        }
        if (window.innerWidth < 992 && !isTablet) {
            this.setState({ isTablet: true })
        }
        if (window.innerWidth >= 991 && isTablet) {
            this.setState({ isTablet: false })
        }
        if (window.innerWidth < 480 && !isTrueMobile) {
            this.setState({ isTrueMobile: true })
        }
        if (window.innerWidth >= 481 && isTrueMobile) {
            this.setState({ isTrueMobile: false })
        }
    }

    componentDidMount() {
        const { pathname, search } = window.location;
        if (this.props.location && this.props.location.state)
            this.setSignUpData(this.props.location.state.signUpData);

        this.props.getProductGroups();
        this.props.getFAQs();

        this.handleResize();

        Auth.currentAuthenticatedUser().then(data => {
            this.setState({ isSignedIn: true });
            this.props.getUser();
            Analytics.setUser(data.username);
            this.handlePathAndSearch(pathname, search, true)
        }).catch(err => {
            this.handlePathAndSearch(pathname, search)
            this.setIdentityBreadcrumb();
        })


    }

    componentDidUpdate() {
        const {
            productGroups,
            productGroupsError,
            FAQs,
            FAQError,
            landingData,
            postProspectError,
            postProspectSuccess,
            landingError,
            getUserError
        } = this.props;

        const { employerSlug, employer, isSignedIn, prospectPosted } = this.state;

        if (landingError && this.state.landingError) {
            this.setState({ landingError });
            Sentry.captureException(landingError);
        }

        if (landingData && !this.state.landingData) {
            this.setState({ landingData, ...landingData })
        }
        if (productGroups && !this.state.productGroups) {
            const productGroupsMap = {};
            productGroups.forEach(pg => {
                productGroupsMap[pg.groupName] = pg;
            })
            this.setState({ productGroups, productGroupsMap });
        }
        if (productGroupsError && !this.state.productGroupsError) {
            this.setState({ productGroupsError });
        }

        if (FAQs && !this.state.FAQs) {
            this.setState({ FAQs });
        }
        if (FAQError && !this.state.FAQError) {
            this.setState({ FAQError });
        }

        if ((postProspectSuccess || postProspectError) && !prospectPosted) {
            this.setState({ prospectPosted: true })
        }

        if (getUserError !== this.state.getUserError) {
            this.setState({ getUserError });
            if (isSignedIn && getUserError)
                this.setState({ isSignedIn: !getUserError });
        }

        if (employer && employerSlug && !employer.employerSlug) {
            employer.employerSlug = employerSlug;
            this.setState({ employer })
        }
    }

    setSignUpData(signUpData) {
        try {
            this.setState({ signUpData })
            this.setStateForKeyValue(signUpData)
            const { zipcode, utm_campaign } = signUpData
            if (utm_campaign) {
                this.redirectFromUtmCampaign(utm_campaign);
            }
            if (zipcode) marginForCountdown();
        } catch (err) {
            console.error(err);
        }
    }

    handlePathAndSearch(pathname, search, isSignedIn) {
        if (pathname !== "/") {
            this.handlePath(pathname, isSignedIn, search)
        } else
            this.props.getLanding(search);

        if (search)
            this.setFromSearchParams(search)
        else
            this.setState({ referrer: document.referrer })
    }

    redirectFromUtmCampaign(utm_campaign) {
        if (utm_campaign.includes("fetch_atx_")) {
            const campaignShortName = utm_campaign.replace("fetch_atx_", "");
            this.props.history.push(`/${campaignShortName}`)
        }
    }

    handlePath(pathname, isSignedIn, search) {
        try {
            const formattedPathname = pathname.split('/')[1].toLowerCase();
            const { history } = this.props;
            let campaign;
            let campaignDefault;
            let employerSlug;

            if (pathname.includes('landing')) {
                this.props.getLanding(search);
            } else {
                switch (formattedPathname) {
                    case "mock":
                        this.setState({ isMock: true });
                        break;
                    case "unmock":
                        this.setState({ isMock: false })
                        break;
                    case "wpk":
                    case "watersPark":
                        campaign = "wpk"
                        break;
                    case "sdn":
                    case "standarddomain":
                        campaign = "sdn"
                        break;
                    case 'employer':
                        campaign = this.props.match.params.employerSlug;
                        break;
                    case 'employee_perk':
                        employerSlug = this.props.match.params.employerSlug;
                        if (isSignedIn) {
                            this.props.history.push(`/dashboard/account/enroll_employee/${employerSlug}`)
                            return
                        } else {
                            this.setState({ isEmployeePerk: true, employerSlug })
                        }
                        break;
                    case 'ref':
                        campaignDefault = getRefCampaign(this.props.match.params.id);
                        break;
                    case 'coupon':
                        campaignDefault = getCouponCampaign(this.props.match.params.couponSlug);
                        break;
                    case 'customerref':
                        campaignDefault = customerReferralCampaign(this.props.match.params.id);
                        break;
                    default:
                        campaign = formattedPathname
                        break;
                }
            }

            if (campaign)
                campaignDefault = campaigns[campaign];

            const { searchData } = parseSearchParams(search);
            const campaignData = Object.assign({}, campaignDefault, searchData);

            if (Object.keys(campaignData).length) {
                const {
                    campaignLanding,
                    employer,
                    rc,
                    utm_campaign,
                    utm_content,
                    utm_medium,
                    utm_source,
                    refCust,
                } = campaignData

                let searchString = `?utm_campaign=${utm_campaign}&utm_content=${utm_content}&utm_source=${utm_source}&utm_medium=${utm_medium}`

                if (employer)
                    searchString += `&employer=${employer}`;
                else if (rc)
                    searchString += `&rc=${rc}`;
                else if (refCust)
                    searchString += `&refCust=${refCust}`;

                if (campaignLanding) {
                    history.push({
                        pathname: campaignLanding,
                        search: searchString
                    })
                } else {
                    this.props.getLanding(search);
                }
            } else {
                this.props.getLanding(search);
            }
        } catch (err) {
            Sentry.captureException(err);
            this.props.getLanding();
        }
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    setFromSearchParams(search) {
        const { searchData, error } = parseSearchParams(search);
        if (!error) {
            const { utm_campaign, utm_content, utm_source, employer, fbclid, rc, refCust } = searchData;

            const referrer = searchData.referrer ? searchData.referrer : document.referrer;

            // Get headers from utm_content
            let header, subheader;
            const headers = utm_content ? campaignHeaders[utm_content] : null;
            if (headers) {
                header = headers.header;
                subheader = headers.subheader;
            }

            this.setState({ utm_campaign, utm_content, utm_source, employerSlug: employer, fbclid, rc, header, subheader, refCust, referrer });

            this.props.postProspect({
                campaignId: utm_campaign,
                referrer,
                attributes: {
                    employer,
                    fbclid,
                    rc,
                    refCust,
                    utm_content,
                    utm_source,
                }
            })
        } else {
            logger.error(error)
        }
    }

    setIdentityBreadcrumb() {
        Auth.currentCredentials().then(creds => {
            if (creds && creds._identityId) {
                Analytics.setUser(creds._identityId);
            }
        }).catch(err => {
            Sentry.captureException(err);
        })
    }

    toggleMenu() {
        this.setState({ isShowingMenu: !this.state.isShowingMenu })
    }

    handleKeydown(e) {
        if (e.keyCode === 13) {
            this.onGetStartedClick();
        }
    }

    onGetStartedClick() {
        const {
            coupon,
            dropoffZipcodes,
            employer,
            employerId,
            employerSlug,
            isEmployeePerk,
            isSignedIn,
            landingData,
            newCoupon,
            productGroups,
            productGroupsMap,
            rc,
            refCust,
            referrer,
            sdCredit,
            signupsOpen,
            utm_campaign,
            utm_content,
            utm_source,
            zipcode,
            zipcodes
        } = this.state;

        if (isSignedIn) {
            this.onDashboardClick()
        } else {
            const signUpData = {
                coupon,
                employer,
                employerId,
                employerSlug,
                isEmployeePerk,
                newCoupon,
                productGroups,
                productGroupsMap,
                rc,
                refCust,
                referrer,
                signupsOpen,
                sdCredit,
                utm_campaign,
                utm_content,
                utm_source,
                zipcode,
                zipcodes: zipcodes ? zipcodes : dropoffZipcodes ? dropoffZipcodes : defaultZipcodes
            };

            const pushInterviewFlow = (featureFlags.isUngated && landingData && signupsOpen);
            const pathname = pushInterviewFlow ? '/start/1' : '/how-it-works';

            this.props.history.push({
                pathname,
                search: window.location.search,
                state: { signUpData }
            });
        }
    }

    onDashboardClick() {
        this.props.history.push('/dashboard');
    }

    renderFixedNav() {
        const { aptCampaign, zipcode, isTablet, isShowingMenu, isMobile, utm_campaign, isSignedIn } = this.state;
        const buttonText = isSignedIn ? "My Dashboard" : "Get Started";
        return (
            <LandingNav
                history={this.props.history}
                isTablet={isTablet}
                isMobile={isMobile}
                isSignedIn={isSignedIn}
                aptCampaign={aptCampaign}
                spaceTop={zipcode}
                buttonText={buttonText}
                next={this.onGetStartedClick}
                toggleMenu={this.toggleMenu}
                isShowingMenu={isShowingMenu}
                utm_campaign={utm_campaign}
            />
        )
    }

    renderProductDetail() {
        const { showProduct } = this.state;
        if (showProduct) {
            return (
                <ProductDetail productGroup={showProduct} close={() => this.setState({ showProduct: undefined })} />
            )
        }
    }

    renderCovidResponse() {
        const { showCovidResponse } = this.state;
        if (showCovidResponse) {
            return (
                <DocModal doc="covid" hide={() => this.setState({ showCovidResponse: false })} />
            )
        }
    }

    renderHeroSection() {
        const {
            header,
            isEmployeePerk,
            isMobile,
            isSignedIn,
            isTablet,
            newCoupon,
            sdCredit,
            signUpData,
            subheader,
            utm_campaign,
            utm_content
        } = this.state;
        const { history } = this.props;

        const positionedImages = [
            <HomeHeroPattern key="heroSectionImg" src={images.home_hero_pattern} alt="" isHome={true} />
        ]
        return (
            <Hero
                ctaCopy="Get Started"
                coupon={newCoupon}
                sdCredit={sdCredit}
                header={header ? header : "Get The Products You Use Everyday Without The Hassle!"}
                heroImg={{
                    alt: "Supply Drop Hero Image - Household Essentials Delivered to Your Door",
                    src: images.home_hero
                }}
                handleCTAClick={this.onGetStartedClick}
                history={history}
                isEmployeePerk={isEmployeePerk}
                isMobile={isMobile}
                isSignedIn={isSignedIn}
                isTablet={isTablet}
                positionedImages={positionedImages}
                signUpData={signUpData}
                subheader={subheader ? subheader : "Your personalized smart subscription ensures you effortlessly get what you need when you need it based on your home’s unique usage."}
                utm_campaign={utm_campaign}
                utm_content={utm_content}
                imgContainerStyles={{ zIndex: 0 }}
                additionalOverflowStyles={{ maxWidth: "none" }}
            />
        )
    }

    renderBundles() {
        try {
            const rows = Object.entries(bundles).map(entry => {
                const bundleId = entry[0], bundle = entry[1];
                const { icon, title, displayProductTypes } = bundle;
                const productRows = displayProductTypes.map(productType => {
                    return <ProductCheckmarkRow key={productType} productType={productType} landing={true} />
                })
                return (
                    <Bundle key={title} onClick={() => this.props.history.push(`/products/bundles/${bundleId}`)}>
                        <BundleImg src={icon} alt={title} />
                        <h4 className="landing-bundle-header">{title}</h4>
                        <div className="landing-bundle-checkmark-container">
                            {productRows}
                        </div>
                    </Bundle>
                )
            })
            return (
                <Section noBorder={true}>
                    <SectionBody style={{ paddingTop: 76 }}>
                        <TextCenter><H2>Everything your household needs</H2></TextCenter>
                        {rows}
                        <TextCenter>
                            <CTA onClick={this.onGetStartedClick}>Build your custom plan</CTA>
                        </TextCenter>
                    </SectionBody>
                </Section>
            )
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    renderFAQs() {
        const { FAQs, FAQError } = this.state;
        let FAQMap = [];
        if (FAQs && !FAQError) {
            Object.entries(FAQs.FAQs).forEach(entry => {
                const title = entry[0];
                const bodyHtml = entry[1].bodyHtml;
                if (entry[1].category === "New Members") {
                    FAQMap.push(<FAQ key={title} title={title} bodyHtml={bodyHtml} />)
                }
            })
        } else {
            const faqList = [
                {
                    title: "Is Supply Drop available in my area?",
                    bodyHtml: `<div>We are currently offering this magical experience only to certain zip codes in Austin, TX. Just click Get Started and enter your zip code to see if we are in your area or to sign-up for our wait list so we can alert you when we are in your area.</div>`
                },
                {
                    title: "Is shipping always free?",
                    bodyHtml: `<div>Yes, shipping is always free for orders over $20. If you need only a small amount of supplies on a regular basis we will automatically adjust your plan to deliver them every other month. When we prepare your monthly drop we will also look ahead and if you won’t need much the following month, we’ll go ahead and ship those supplies as well. This saves you money and it is also better for the planet.</div>`
                },
                {
                    title: "Can I change products or adjust quantities?",
                    bodyHtml: `<div>Of course! Text us or visit your dashboard and we’ll show you more options for you to choose from. You can make adjustments to the brands or the quantities you can get. It’s also easy to skip a month if you’ve got too much.</div>`
                },
                {
                    title: "How does the cost compare to my grocery store?",
                    bodyHtml: `<div>Our costs are comparable to shopping online or at your local store but without the hassle! There are also no hidden delivery fees or tips.</div>`
                },
                {
                    title: "Why do you need my mobile number and what do you use it for?",
                    bodyHtml: `
                        <Fragment>
                            <div>Your mobile number is used to verify your identity and keep your account secure. We’ll also text you when we are preparing a new drop for you so that you can review and make any adjustments. It’s easy and convenient. We may also text you to make sure you are happy with your drop, for example if you change the type of paper towel you get we may text you asking if you are happy with the change. But we’ll never spam you. We hate spam. </div><br />
                        <div>You can also text us, and because you’ve verified your number, we know it’s you. You can let us know you’re going out of town, having guests, got a new puppy, or are running low on something, and we'll make any adjustments for you!</div>
                        </Fragment>
                    `
                },
                {
                    title: "Can I pause or cancel anytime?",
                    bodyHtml: `<div>Of course, but we think you‘ll be so delighted that you won't want to! For more info visit <a class="pointer" href="https://supplydrop.com/dashboard/account">My Account</u> in your dashboard.</div>`
                },
                {
                    title: "Do you use my information for anything else?",
                    bodyHtml: `<div>Privacy is important to us. We hate spam and will never sell your personal information.</div>`
                },
                {
                    title: "What is your response to COVID-19?",
                    bodyHtml: `<div>Read our response to COVID-19 <a class="pointer" href="https://supplydrop.com/covid.html">here.</u></div>`
                },
                {
                    title: "How can I contact you?",
                    bodyHtml: `<div>
                        Existing customers can text us at <a href="tel:30389">30389</a>. Not a customer yet? You can email us at <a href="mailto:care@supplydrop.com">care@supplydrop.com</a> or give us a call at <a href="tel:+15125373009">(512) 537-3009</a>. We look forward to hearing from you!
                    </div>`
                },
            ]
            FAQMap = faqList.map(faq => {
                const { title, bodyHtml } = faq;
                return <FAQ key={title} title={title} bodyHtml={bodyHtml} />
            })
        }
        return (
            <Section noBorder={true}>
                <SectionBody noPadding={true}>
                    <FAQsContainer id="faqs">
                        <H2 style={{ marginBottom: 28 }}>FAQs</H2>
                        <FAQsBody>
                            {FAQMap}
                        </FAQsBody>
                    </FAQsContainer>
                </SectionBody>
            </Section>
        )
    }

    renderHowItWorks() {
        return (
            <Section noBorder={true}>
                <HowItWorksSection />
            </Section>
        )
    }

    renderSmartSubscription() {
        const { isTablet, isMobile } = this.state;

        const height = isMobile ? "222px" : isTablet ? "284px" : "400px";
        const width = isTablet ? "452px !important" : "50%";
        const overflowStyles = isTablet ? { left: 0, right: 0, top: 20 } : { left: 'auto', right: 0, top: 40 };
        const secondaryBtn = <Link to={{ pathname: "/science", search: window.location.search }}><Button variant="outlined" color="primary" className="space-top">Show me the Science <span style={{ paddingLeft: 12 }} className="fa fa-chevron-right" /></Button></Link>

        return (
            <LandingTwoUpSection
                header="Your Toilet Paper Takes Care of Itself, Seriously."
                mainImg={{
                    alt: "A smart subscription",
                    src: images.smart_subscription,
                }}
                isReverse={!isTablet}
                isRaised={true}
                raisedSectionStyles={{
                    zIndex: 5,
                    background: householdPatternedBackground("rgb(229, 242, 255)"),
                    minHeight: isTablet ? 298 : 400,
                    paddingTop: isTablet ? 0 : 'auto',
                    overflowY: "hidden",
                    ...flexCenter
                }}
                cta={secondaryBtn}
                isOverflowY={true}
                overflowContainerStyles={{
                    height,
                    width,
                    marginBottom: -20
                }}
                overflowStyles={overflowStyles}
            />
        )
    }

    renderTestimonialsAndBetterWay() {
        const { isSignedIn } = this.state
        return (
            <div style={{ background: conversationalPatternedBackground("#E0EFFF") }}>
                <TestimonialCardCarousel testimonials={supplyDropTestimonials} style={{ paddingTop: 80, paddingBottom: 80 }} />
                <BetterWay
                    signUpData={this.state.signUpData}
                    isRelative={true}
                    pathname="/how-it-works"
                    isSignedIn={isSignedIn}
                    ctaCopy="Get Started"
                    handleCTAClick={this.onGetStartedClick}
                />
            </div>
        )
    }

    renderBrandsCarousel() {
        return (
            <Section noBorder={true}>
                <SectionBody>
                    <TextCenter><H2>Get Your Favorite Brands</H2></TextCenter>
                    <BrandsCarousel />
                    <TextCenter><Link to={{ pathname: "/products/bundles/everyday", search: window.location.search }}><Button variant="outlined" color="primary" className="space-top">View All Products <span style={{ paddingLeft: 12 }} className="fa fa-chevron-right" /></Button></Link></TextCenter>
                </SectionBody>
            </Section>
        )
    }

    renderValueProps() {
        const { isTablet, isTrueMobile } = this.state;

        const height = isTablet ? 375 : 475;
        const width = isTablet ? "calc(100% + 40px) !important" : "50%";
        const overflowStyles = isTablet ? { position: "static", marginLeft: isTrueMobile ? -100 : -20, marginRight: isTrueMobile ? 0 : -20, height: 375, maxWidth: "none", overflowX: "hidden" } : { left: 'auto', right: 0, top: 40 };

        return (
            <LandingTwoUpSection
                mainImg={{
                    alt: "What you can expect with Supply Drop",
                    src: images.home_value_props,
                }}
                isReverse={true}
                isRaised={true}
                body={(
                    <ValueProps isLanding={true} />
                )}
                raisedSectionStyles={{
                    zIndex: 5,
                    background: householdPatternedBackground("rgb(245,246,249)"),
                    minHeight: isTablet ? 298 : 400,
                    overflowY: "hidden",
                    ...flexCenter
                }}
                isOverflowX={!isTablet}
                isOverflowY={true}
                overflowContainerStyles={{
                    height,
                    width,
                    marginBottom: -0,
                    marginTop: isTablet ? 0 : -39,
                    marginLeft: isTablet ? -20 : 0,
                    marginRight: isTablet ? -20 : 0,
                    maxWidth: isTablet ? "100vw" : "100%",
                    padding: isTablet ? "0 12px 12px" : 12
                }}
                style={{
                    paddingTop: 0,
                    paddingBottom: 0
                }}
                overflowStyles={overflowStyles}
            />
        )
    }

    render() {
        return (
            <Main>
                <MetaTags
                    title="Supply Drop - Get Your Favorite Brands Delivered When You Need Them"
                    description="Try Supply Drop’s Try Supply Drop’s Smart Subscription today! We deliver household supplies you use every day based on your unique usage. Best prices. Free delivery."
                    robots="follow"
                />
                {this.renderFixedNav()}
                {this.renderProductDetail()}
                {this.renderCovidResponse()}
                <LandingContainer>
                    {this.renderHeroSection()}
                    <PageDivider isColored={true} isReverse={true} style={{ marginTop: -87, zIndex: 1 }} />
                    {this.renderHowItWorks()}
                    {this.renderValueProps()}
                    {this.renderBrandsCarousel()}
                    {this.renderSmartSubscription()}
                    {this.renderBundles()}
                    <PageDivider isReverse={true} />
                    {this.renderTestimonialsAndBetterWay()}
                    <PageDivider />
                    {this.renderFAQs()}
                </LandingContainer>
                <Footer
                    history={this.props.history}
                    landing={true}
                />
            </Main>
        );
    }
}
const mapStateToProps = (state) => {
    const { user, getUserError, postProspectSuccess, postProspectError, postingProspect } = state.user;
    const { landingData, gettingLandingData, landingError } = state.order;
    const { FAQs, FAQError, gettingFAQs } = state.FAQs;
    const { productGroups, productGroupsError } = state.product;
    return {
        landingData,
        gettingLandingData,
        landingError,
        productGroups,
        productGroupsError,
        FAQs,
        FAQError,
        gettingFAQs,
        postProspectSuccess,
        postProspectError,
        postingProspect,
        user,
        getUserError
    };
}

export default connect(mapStateToProps, {
    getLanding,
    getProductGroups,
    getFAQs,
    postProspect,
    getUser
})(Home);
