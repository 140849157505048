import React from 'react';
import {
    CadenceAlertContainer as Container,
    CadenceAlertWrapper as Wrapper,
    ValuePropsTitle as Title,
    CadenceAlertBody as Body,
    CadenceLink
} from '../../../Style';
import { CadenceCalendar } from './CadenceCalendar';

export const CadenceAlert = ({ title, months, onclick, dashboardRecommendation }) => {
    const planCopy = () => {
        return (
            <Body>
                <Title>{title} Plan</Title>
                <div>Your Supply Drop is delivered every {months > 1 && months + " "}month{months > 1 && 's'}.</div>
                <CadenceLink onClick={onclick}>Learn More</CadenceLink>
            </Body>
        )
    }

    return (
        <Container>
            <Wrapper dashboardRecommendation={dashboardRecommendation}>
                <CadenceCalendar onclick={onclick} months={months} />
                {planCopy()}
            </Wrapper>
        </Container>
    )
}
