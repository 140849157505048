import React, { Fragment } from 'react';
import ContentfulImage from '../../../../contentful';
import { ProductDropdown } from '.';
import {
    ManifestBundleProductContainer as Container,
    ManifestBundleProductBody as Body,
    ManifestBundleProductMain as Main,
    ProductDisplayName,
    UndoLinkDivider as Divider,
    UndoCopy
} from '../../../../Style/Manifest'
import { formatProductType, getConsumptionEstimateMonths } from '../../../../actions';
import Button from '@material-ui/core/Button'
import moment from 'moment';
import { refresh } from '../../../../assets/miscellaneous';
import { Caption, Subtitle2, Body1 } from '../../../../Style/DesignSystem/Typography';

export const ManifestBundleProduct = ({
    bundleShortName,
    isDashboard,
    last,
    onlyOffering,
    onQuantityChange,
    productGroup,
    showDescription,
    showOtherBrands,
    showRemove,
    cta,
    view,
    needsTrashQuestion,
    handleDropdownSelect,
    selectProductGroup,
    selected,
    hasSelectedProductGroup,
    isProductGroupSkipped,
    isProductGroupRemoved,
    undoSkip,
    undoRemove,
    skipRemovedProductGroup
}) => {
    const {
        articlesPerDay,
        articleType,
        brandName,
        cappedArticleCounts,
        displayName,
        groupName,
        imageUrl,
        proposedArticles,
        pricePerArticle,
        productType,
        replaceWith,
    } = productGroup;

    const formattedProductType = formatProductType(productType);
    const fullProductTitle = `${brandName} ${displayName} ${formattedProductType}`

    const articles = productGroup.articles !== undefined ? productGroup.articles : proposedArticles

    const months = articlesPerDay ? getConsumptionEstimateMonths(articlesPerDay, articles, proposedArticles, isDashboard) : 1;

    const refillAlert = () => {
        if (replaceWith)
            return <span onClick={() => showDescription(replaceWith)}><br />Future deliveries will be refills</span>
    }

    const brandLink = () => {
        if (!(onlyOffering || cta === "change") && !isProductGroupSkipped && !isProductGroupRemoved) {
            return (
                <Button className="pill-button" variant="outlined" color="primary" onClick={showOtherBrands}>
                    Change Brand
                </Button>
            )
        }
    }

    const renderDropdown = () => {
        if (!isProductGroupSkipped && !isProductGroupRemoved) {
            return (
                <ProductDropdown
                    onQuantityChange={onQuantityChange}
                    possibleArticleCounts={cappedArticleCounts}
                    articles={articles}
                    articleType={articleType}
                    pricePerArticle={pricePerArticle}
                    showRemove={showRemove}
                    groupName={groupName}
                    productType={productType}
                />
            )
        }
    }

    const renderUndoCopy = () => {
        if (isProductGroupSkipped) {
            return (
                <UndoCopy>
                    <b>{fullProductTitle}</b> skipped, you’ll receive your next shipment in the following delivery.
                </UndoCopy>
            )
        } else if (isProductGroupRemoved) {
            return (
                <UndoCopy>
                    <b>{fullProductTitle}</b> removed.
                </UndoCopy>
            )
        }
    }

    const renderUndoButtons = () => {
        if (isProductGroupSkipped) {
            return <Button color="primary" onClick={undoSkip}>Undo</Button>
        } else if (isProductGroupRemoved) {
            return (
                <Fragment>
                    <Button color="primary" onClick={undoRemove}>Undo</Button>
                    <Divider />
                    <Button color="primary" onClick={skipRemovedProductGroup}>Start Later</Button>
                </Fragment>
            )
        }
    }

    const thisLastMonths = () => {
        if (months > 0 && !isProductGroupSkipped && !isProductGroupRemoved) {
            let displayMonths = Math.floor(months);
            if (!displayMonths) displayMonths = 1;
            return (
                <Caption>
                    <img src={refresh} alt="Replenishment date" /> This should last you {displayMonths} month{displayMonths > 1 ? 's' : ''}{displayMonths > 2 ? " (until " + moment().add(displayMonths, "M").format("MMMM") + ")" : ""}
                    {refillAlert()}
                </Caption>
            )
        }
    }

    const ctaButton = () => {
        if ((cta === "add" || cta === "change") && view !== "complete" && !isProductGroupRemoved && !isProductGroupSkipped) {
            let copy = "Select"
            if ((cta === "add" && !needsTrashQuestion) || !hasSelectedProductGroup) copy = "Add to plan"
            else if (selected) copy = "Selected"
            else if (!needsTrashQuestion) copy = "Change to this brand"

            const clickFunction = () => needsTrashQuestion ? handleDropdownSelect(productType) : selectProductGroup(groupName, cta, bundleShortName);

            return (
                <Button
                    onClick={clickFunction}
                    variant="contained"
                    color="primary"
                    className="space-top"
                    disabled={selected}
                >{copy}</Button>
            )
        }
    }

    const renderTitle = () => {
        if (!isProductGroupSkipped && !isProductGroupRemoved) {
            return (
                <div className="flex-row flex-space-between">
                    <div className="text-left">
                        <Subtitle2 as={ProductDisplayName}>{brandName} {displayName}</Subtitle2>
                        <span><Body1 className="no-margin">{formatProductType(productType)}</Body1></span>
                    </div>
                    {brandLink()}
                </div>
            )
        }
    }

    return (
        <Container className={`bundle-product${last ? " last" : ""}`}>
            <Main isDrawer={!!cta} isDashboard={isDashboard}>
                <ContentfulImage
                    className="bundle-product-img pointer"
                    src={imageUrl}
                    alt={`${brandName} ${displayName}`}
                    defaultWidth="150"
                    defaultHeight="150"
                    onClick={() => showDescription(groupName)}
                />
                <Body>
                    {renderUndoCopy()}
                    {renderTitle()}
                    {renderUndoButtons()}
                    {renderDropdown()}
                    {thisLastMonths()}
                    {ctaButton()}
                </Body>
            </Main>
        </Container>
    )
}