import React, { Component, Fragment } from 'react';
import Menu from './Subcomponents/Menu';
import {
    Loading,
    Saving,
    // Footer,
    ErrorMessage,
    MetaTags,
    PhoneBanner,
    SingleChoiceModal,
    FormError
} from '../Common';
import { connect } from 'react-redux';
import * as images from '../../assets';
import StripeForm from './Subcomponents/StripeForm';
import { Elements } from 'react-stripe-elements';
import { States } from '../Checkout/states';
import { isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment';
import ReactGA from 'react-ga';
import Analytics from "../../analytics";
import * as Sentry from '@sentry/browser';
import PhoneVerification from '../Common/Connected/PhoneVerification';
import {
    appendBodyNoScroll,
    clearCustomerState,
    createGlobalAuthError,
    clearOrdersState,
    formatInterview,
    formatPhone,
    enrollCustomer,
    getLanding,
    getMagicLink,
    getOrders,
    getUser,
    putInterview,
    recharge,
    removeBodyNoScroll,
    reverseFormatPhone,
    updateCustomer,
    updateSource,
    validateCityAndNames,
    validateEmail,
    validateWorkEmail,
    validateStreetAddress,
    validateZip,
} from '../../actions';
import { AccountP as P } from '../../Style';
import Household from '../Interview/Everyday/Household';
import { signIn } from './SignIn';
import { signOut } from '../../helpers/SignOut';
import { Logger } from '../../helpers/Logger';
const logger = new Logger();
const prod = (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT === 'production');

class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nameValid: true,
            addressValid: true,
            zipcodeValid: true,
            cityValid: true,
            stateValid: true,
            firstNameValid: true,
            lastNameValid: true,
            emailValid: true,
            workEmailValid: true,
            phoneValid: true,
            dashboardData: {}
        };

        this.setToEdit = this.setToEdit.bind(this);
        this.onShippingChange = this.onShippingChange.bind(this);
        this.cancelShippingEdits = this.cancelShippingEdits.bind(this);
        this.cancelBillingEdits = this.cancelBillingEdits.bind(this);
        this.cancelDetailsEdits = this.cancelDetailsEdits.bind(this);
        this.updateShipping = this.updateShipping.bind(this);
        this.validateShipping = this.validateShipping.bind(this);
        this.updateBilling = this.updateBilling.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.updateDetails = this.updateDetails.bind(this);
        this.validateDetails = this.validateDetails.bind(this);
        this.togglePauseModal = this.togglePauseModal.bind(this);
        this.updateInterview = this.updateInterview.bind(this);
        this.setHousehold = this.setHousehold.bind(this);
        this.resetHousehold = this.resetHousehold.bind(this);
        this.setToHouseholdEdit = this.setToHouseholdEdit.bind(this);
        this.handleSignedInUser = this.handleSignedInUser.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setUserData = this.setUserData.bind(this);
        this.cancelWorkEdits = this.cancelWorkEdits.bind(this);
        this.updateEnrollment = this.updateEnrollment.bind(this);
        this.setEmployerFromLandingData = this.setEmployerFromLandingData.bind(this);
        this.attemptRecharge = this.attemptRecharge.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
        window.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
        window.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentDidMount() {
        this.props.clearCustomerState(true);
        signIn(this.props.location.search, this.props.history).then(user => {
            this.handleSignedInUser(user);
            this.getDashboardData();
        }).catch(({ magicLinkParams, error }) => {
            if (error && error.name === 'AuthError')
                this.props.createGlobalAuthError('signed in user Auth error')
            if (magicLinkParams) {
                const { u, s, channel } = magicLinkParams;
                this.props.getMagicLink(null, window.location.pathname, channel, u);
                this.setState({ magicLinkPath: `u=${u}&s=${s}` })
            } else {
                this.props.history.push('/login')
            }
        })

        const states = States.map(s => {
            if (s.iso) {
                return (
                    <option key={s.iso} value={s.iso}>
                        {s.name}
                    </option>
                )
            }
            return undefined;
        }).filter(s => s !== undefined);
        this.setState({ states });
    }

    componentDidUpdate() {
        const {
            user,
            updateCustomerError,
            updateCustomerSuccess,
            updatingCustomer,
            putSourceSuccess,
            putSourceError,
            updatingSource,
            orders,
            // landingError,
            landingData,
            enrollingCustomer,
            enrollCustomerSuccess,
            enrollCustomerError,
            rechargeSuccess,
            rechargeError,
            history,
            signedInAuthErr,
            nonSignedInAuthErr,
            gettingMagicLink,
            magicLink,
            magicLinkErr
        } = this.props;

        const { employer, customer, enrolling, dashboardData, globalError, magicLinkPath } = this.state;

        if (landingData && !this.state.landingData) {
            this.setState({ landingData });
            this.setEmployerFromLandingData(landingData)
        }

        if (user && !this.state.user) {
            this.setState({ user })
            this.setUserData(user, dashboardData)
        }
        if (this.state.updating && !updatingCustomer && !updatingSource && !enrollingCustomer) {
            if (updateCustomerSuccess) {
                this.props.clearCustomerState(true);
                setTimeout(() => {
                    this.setState({ updating: false, user: undefined, shippingEdit: false, detailsEdit: false, workEdit: false });
                    this.props.getUser();
                }, 3000);
            }
            if (updateCustomerError) {
                setTimeout(() => {
                    this.setState({ error: updateCustomerError });
                    this.setState({ updating: false });
                    this.props.clearCustomerState();
                }, 3000);
            }
            if (putSourceSuccess) {
                this.props.clearCustomerState(true);
                setTimeout(() => {
                    this.setState({ updating: false, billingEdit: false, user: undefined });
                    this.props.getUser();
                }, 3000);
            }
            if (putSourceError) {
                setTimeout(() => {
                    this.setState({ error: updateCustomerError });
                    this.setState({ updating: false });
                    this.props.clearCustomerState();
                }, 3000);
            }
            if (rechargeSuccess) {
                this.setState({ order: undefined, orders: undefined })
                this.props.clearOrdersState();
                this.props.getOrders();
            }
            if (rechargeError) {
                this.setState({ order: undefined, orders: undefined })
                this.props.clearOrdersState();
                this.props.getOrders();
            }
            if (enrollCustomerSuccess) {
                this.props.clearCustomerState(true);
                setTimeout(() => {
                    this.props.history.push('/dashboard/account')
                }, 3000);
            }
            if (enrollCustomerError) {
                setTimeout(() => {
                    this.setState({ error: enrollCustomerError });
                    this.setState({ updating: false });
                    this.props.clearCustomerState();
                }, 3000);
            }
        }
        if (orders && !this.state.orders) {
            setTimeout(() => {
                try {
                    let order;
                    if (orders.length > 1) {
                        order = orders.sort((a, b) => {
                            if (moment(a.expectedShipDate).valueOf() > moment(b.expectedShipDate).valueOf()) { return -1; }
                            if (moment(a.expectedShipDate).valueOf() < moment(b.expectedShipDate).valueOf()) { return 1; }
                            return 0;
                        }).filter(o => moment(o.expectedShipDate).valueOf() < moment().valueOf())[0];
                    } else {
                        order = orders[0]
                    }
                    dashboardData.order = order;
                    dashboardData.orders = orders;

                    this.setState({ orders, order, dashboardData })
                } catch (err) {
                    console.error(err);
                }
            }, 3000)

        }

        if ((gettingMagicLink || magicLink) && magicLinkPath && !this.state.gettingMagicLink) {
            this.setState({ gettingMagicLink: true })
            this.props.history.push(`/magiclink?${magicLinkPath}`);
        }

        if (magicLinkErr && magicLinkPath && !this.state.gettingMagicLink) {
            this.setState({ gettingMagicLink: true })
            this.props.history.push("/login");
        }

        // To test modal auto render on a customer that is already enrolled
        // if(employer && customer && !enrolling) {
        if (employer && customer && !customer.employer && !enrolling) {
            this.setState({ enrolling: true, workEdit: true })
        }

        if ((signedInAuthErr || nonSignedInAuthErr) && !globalError) {
            this.setState({ globalError: true });
            signOut(history, true)
        }
    }

    handleSignedInUser(user) {
        Analytics.setUser(user.username);
        const { location } = this.props;
        if (user.attributes && !user.attributes.phone_number_verified && (!location.state || !location.state.dashboardData || !location.state.dashboardData.verified)) {
            this.setState({ isUnverified: true })
        }
        this.setState({ signedIn: true })
    }

    setEmployerFromLandingData(landingData) {
        try {
            const { employer } = landingData
            if (employer)
                this.setState({ employer })
        } catch (err) {
            logger.error(err, new Error('Unable to set employer from landingData', landingData.employer, 'LandingData has employer'))
        }
    }

    setUserData(user, dashboardData) {
        try {
            const { customer } = user;
            const {
                firstName,
                lastName,
                email,
                phone,
                workEmail,
                employer,
                interview
            } = customer;

            const deformattedPhone = reverseFormatPhone(phone);
            const { family, pets } = interview;
            const household = { family, pets };

            this.setHousehold(household);

            dashboardData.user = user;

            if (employer) {
                const { slug } = employer;
                this.setState({ employer })
                if (slug) {
                    this.setState({ employerSlug: slug })
                } else {
                    logger.error(new Error('employerSlug missing from employer'))
                }
            }

            this.setState({
                user,
                shipping: customer.shipping,
                firstName,
                lastName,
                email,
                phone: deformattedPhone,
                workEmail,
                customer,
                interview,
                dashboardData
            });
            if (user.customer.shipping.state === "TX") {
                this.setState({ isTax: true })
            }
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    }

    getDashboardData() {
        const { location } = this.props;
        try {
            if (location.state && location.state.dashboardData) {
                const { dashboardData } = location.state;
                const { orders, order } = dashboardData;

                this.props.getUser();

                if (orders && order)
                    this.setState({ orders, order })
                else
                    this.props.getOrders();

                this.setState({ dashboardData });
            } else {
                throw new Error("No location state data");
            }
        } catch (err) {
            if (err.message !== "No location state data") {
                console.error(err);
                Sentry.captureException(err);
            }
            this.props.getUser();
            this.props.getOrders();
            if (location.pathname.includes('enroll_employee')) {
                const { match: { params: { employerSlug } } } = this.props;
                this.props.getLanding(`?employer=${employerSlug}`)
                this.setState({ employerSlug })
            }
        }
    }

    updateInterview(household) {
        const { interview, dashboardData } = this.state;
        try {
            const { family, pets } = household;
            const formattedFamily = family.map(member => {
                const { age, staysHome, gender } = member;
                return { age, staysHome, gender }
            })
            interview.family = formattedFamily;
            interview.pets = pets;
            const formattedRes = formatInterview(interview);
            if (formattedRes.interview) {
                this.props.putInterview(formattedRes.interview);
                delete dashboardData.user;
                this.props.clearCustomerState(true);
                this.setState({ interview, household, householdEdit: false, isHouseholdUpdated: true })
                removeBodyNoScroll();
            } else {
                throw formattedRes.error;
            }
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
            this.setState({ interview, household, householdEdit: false })
            removeBodyNoScroll();
        }
    }

    resetHousehold() {
        const { interview } = this.state;
        const { family, pets } = interview;
        this.setHousehold({ family, pets });
        this.setState({ householdEdit: false })
        removeBodyNoScroll();
    }

    renderHouseholdUpdatedModal() {
        const { dashboardData, isHouseholdUpdated } = this.state;
        if (isHouseholdUpdated) {
            return (
                <SingleChoiceModal
                    title="Household updated"
                    body="By adjusting your household, the product quantities in your next delivery have been adjusted."
                    buttonCopy="Review next delivery"
                    close={() => this.setState({ isHouseholdUpdated: false })}
                    next={() => this.props.history.push({ pathname: "/dashboard", state: { dashboardData } })}
                />
            )
        }
    }

    setHousehold(household) {
        try {
            const { family } = household;
            for (let i = 0; i < family.length; i++) {
                family[i].type = family[i].age > 17 ? "Adult" : "Child";
                family[i].id = i;
            }
            household.family = family;
            this.setState({ household });
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    }

    togglePauseModal() {
        const { showPauseModal } = this.state;
        this.setState({ showPauseModal: !showPauseModal })
    }

    setToEdit(section) {
        this.setState({
            [section]: true,
            // reset previous form validations
            nameValid: true,
            addressValid: true,
            zipcodeValid: true,
            cityValid: true,
            stateValid: true,
            firstNameValid: true,
            lastNameValid: true,
            emailValid: true,
            phoneValid: true,
            workEmailValid: true,
        });
    }

    onShippingChange(field, value) {
				const { shipping } = this.state;
        shipping[field] = value;
        this.setState({ shipping, [`${field}Valid`]: true });
    }

    onDetailsChange(field, value) {
        this.setState({ [field]: value });
    }

    validateDetails(field, value) {
        const { employerSlug } = this.state;
        try {
            switch (field) {
                case 'firstName':
                    this.setState({ firstNameValid: validateCityAndNames(value) })
                    break;
                case 'lastName':
                    this.setState({ lastNameValid: validateCityAndNames(value) })
                    break;
                case 'email':
                    this.setState({ emailValid: validateEmail(value) })
                    break;
                case 'workEmail':
                    const { workEmailValid, workEmailError } = validateWorkEmail(value, employerSlug)
                    this.setState({ workEmailValid, workEmailError })
                    break;
                case 'phone':
                    const formattedPhone = formatPhone(value);
                    this.setState({ phoneValid: isValidPhoneNumber(formattedPhone) })
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.error(err);
        }
    }

    cancelShippingEdits() {
				const { user } = this.state;
				const shipping = {};
        Object.entries(user.customer.shipping).forEach(entry => {
            shipping[entry[0]] = entry[1];
				});
        this.setState({
            shipping,
            shippingEdit: false,
            cityValid: true,
            nameValid: true,
            addressValid: true,
            zipcodeValid: true
				});
    }

    cancelDetailsEdits() {
        const { customer } = this.state;
				const { firstName, lastName, phone, email } = customer;
        this.setState({
            firstName,
            lastName,
            phone: reverseFormatPhone(phone),
            email,
            detailsEdit: false,
            emailValid: true,
            phoneValid: true,
            firstNameValid: true,
            lastNameValid: true
        })
    }

    cancelWorkEdits() {
        const { customer } = this.state;
        this.setState({ workEmail: customer.workEmail, workEdit: false, workEmailError: undefined })
    }

    updateEnrollment() {
        const { workEmail, employer: { employerId }, employerSlug, customer } = this.state;
        const { workEmailValid, workEmailError } = validateWorkEmail(workEmail, employerSlug);
        if (workEmailValid) {
            // TODO: Don't try to update if no changes
            if (customer.employer) {
                // Update work email record only
                this.props.updateCustomer({ workEmail });
            } else {
                // Enroll in employee perk program
                this.props.enrollCustomer({ employerId, workEmail })
            }
            this.setState({ updating: true })
        } else {
            this.setState({ workEmailValid, workEmailError })
        }
    }

    handleKeydown(e) {
        const { householdEdit, detailsEdit, billingEdit, shippingEdit, showPauseModal, showVerificationModal, isHouseholdUpdated } = this.state;
        if (e.keyCode && e.keyCode === 27) {
            if (detailsEdit) {
                this.cancelDetailsEdits()
            } else if (billingEdit) {
                this.cancelBillingEdits()
            } else if (shippingEdit) {
                this.cancelShippingEdits()
            } else if (householdEdit) {
                this.resetHousehold()
            }
            if (showPauseModal)
                this.setState({ showPauseModal: false })
            if (showVerificationModal)
                this.setState({ showVerificationModal: false })
            if (isHouseholdUpdated)
                this.setState({ isHouseholdUpdated: false })
        }
    }

    handleClickOutside(e) {
        try {
            const { householdEdit, detailsEdit, billingEdit, shippingEdit } = this.state;
            const onClickId = e.target.id;
            if (onClickId === "non-account-modal-container") {
                if (detailsEdit) {
                    this.cancelDetailsEdits()
                } else if (billingEdit) {
                    this.cancelBillingEdits()
                } else if (shippingEdit) {
                    this.cancelShippingEdits()
                } else if (householdEdit) {
                    this.resetHousehold()
                }
            }
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    }

    cancelBillingEdits() {
        this.setState({ billingEdit: false })
    }

    updateShipping() {
        const { shipping } = this.state;
        const cityValid = validateCityAndNames(shipping.city);
        const nameValid = validateCityAndNames(shipping.name);
        const addressValid = validateStreetAddress(shipping.address);
        const zipcodeValid = validateZip(shipping.zipcode);
        if (
            cityValid &&
            nameValid &&
            addressValid &&
            zipcodeValid
        ) {
            if (prod) {
                ReactGA.event({ category: 'Customer', action: 'Update' })
            }
            this.props.updateCustomer({ shipping });
            this.setState({ updating: true })
        } else {
            this.setState({
                cityValid,
                nameValid,
                addressValid,
                zipcodeValid
            });
        }
    }

    updateBilling(source) {
        if (prod) {
            ReactGA.event({ category: 'Source', action: 'Update' })
        }
        this.props.updateSource(source);
        this.setState({ updating: true })

        const { order } = this.state;
        try {
            if (order) {
                const shipDatePlus10 = moment(order.expectedShipDate).add(10, "days");
                const rechargeNotAllowed = moment().isAfter(shipDatePlus10, "day");
                if ((order.status === "PaymentFailed" || order.status === "AwaitingPayment") && !rechargeNotAllowed) {
                    this.attemptRecharge();
                }
            }
        }
        catch (err) {
            logger.error(err);
        }

    }

    attemptRecharge() {
        const { order } = this.state;
        setTimeout(() => this.props.recharge(order.orderId), 3000)
    }

    validateShipping(field, value) {
        try {
            switch (field) {
                case 'name':
                    this.setState({ nameValid: validateCityAndNames(value) })
                    break;
                case 'city':
                    this.setState({ cityValid: validateCityAndNames(value) })
                    break;
                case 'address':
                    this.setState({ addressValid: validateStreetAddress(value) })
                    break;
                case 'state':
                    this.setState({ stateValid: !!value })
                    break;
                case 'zipcode':
                    this.setState({ zipcodeValid: validateZip(value) })
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.error(err);
        }
    }

    updateDetails() {
        const {
            user,
            firstName,
            lastName,
            email,
            phone,
            emailValid,
            phoneValid,
            firstNameValid,
            lastNameValid
        } = this.state;

        const { customer } = user;

        const formattedPhone = formatPhone(phone);
        const changes = {};

        if (emailValid && firstNameValid && lastNameValid && phoneValid) {
            if (customer.firstName !== firstName) changes.firstName = firstName;
            if (customer.lastName !== lastName) changes.lastName = lastName;
            if (customer.email !== email) changes.email = email;
            if (customer.phone !== formattedPhone) changes.phone = formattedPhone;
            if (prod) {
                ReactGA.event({ category: 'Customer', action: 'Update' })
            }
            if (Object.keys(changes).length) {
                this.props.updateCustomer(changes);
                this.setState({
                    updating: true,
                    emailValid,
                    firstNameValid,
                    lastNameValid,
                    phoneValid
                })
            } else {
                this.setState({ detailsEdit: false });
            }
        }

    }

    formatPhone(value) {
        let num = '';
        num = "+1" + value.slice(1, 4) + value.slice(6, 9) + value.slice(10, 14);
        return num;
    }

    reverseFormatPhone(value) {
        let num = '';
        num = `(${value.slice(2, 5)}) ${value.slice(5, 8)}-${value.slice(8, 12)}`;
        return num;
    }

    setToHouseholdEdit() {
        this.setState({ householdEdit: true })
        appendBodyNoScroll();
    }

    renderPutModal() {
        const { updating } = this.state;
        const {
            updateCustomerSuccess,
            updateCustomerError,
            enrollCustomerSuccess,
            enrollCustomerError,
            putSourceError,
            putSourceSuccess
        } = this.props;

        if (updating) {
            return (
                <Saving success={(updateCustomerSuccess || putSourceSuccess || enrollCustomerSuccess)} error={(updateCustomerError || putSourceError || enrollCustomerError)} />
            )
        }
    }

    renderPauseModal() {
        const { showPauseModal } = this.state;

        if (showPauseModal) {
            return (
                <SingleChoiceModal
                    title="Pause Or Cancel My Subscription"
                    body={(
                        <Fragment>
                            To pause or cancel your subscription, <a href="sms://30389">please text us at 303-89</a> and our customer service team will take care of everything for you!
                        </Fragment>
                    )}
                    close={this.togglePauseModal}
                    next={this.togglePauseModal}
                    buttonCopy="Got it, thanks!"
                />
            )
        }
    }

    renderShippingSection() {
        const {
            user,
        } = this.state;
        const { customer } = user;
        const { shipping } = customer;
        const { name, address, city, state, zipcode, deliveryInstructions } = shipping;
        return (
            <P key='shippingSection'>
                {name}<br />
                {address}<br />
                {city}, {state} {zipcode}
                {deliveryInstructions ?
                    <Fragment>
                        <br /><br />
                        <u>Delivery Instructions:</u><br />
                        {deliveryInstructions}
                    </Fragment> : null
                }
            </P>
        )
    }

    renderShippingModal() {
        const {
            shippingEdit,
            updating,
            shipping,
            states,
            nameValid,
            addressValid,
            zipcodeValid,
            stateValid,
            cityValid
        } = this.state;

        if (shippingEdit && !updating) {
            return (
                <div id="non-account-modal-container" className="modal-container transparent">
                    <div className="interview-question-modal">
                        <img onClick={this.cancelShippingEdits} className="raised text-right order-x-icon" src={images.btn_close_x} alt="close" />
                        <div className="interview-modal-body$ no-padding">
                            <div className="signin-modal-tab-container">
                                <div className="cancel-modal-body">
                                    <p className="modal-title">
                                        Update Delivery Info
                                </p>
                                    <hr />
                                    <div className="input-wrapper flex-row flex-wrap account-modal">
                                        <div className="modal-input-container full">
                                            <label className={`modal-label ${nameValid ? '' : 'invalid'}`}>Name:</label>
                                            <input
                                                className={`modal-input ${nameValid ? '' : 'invalid'}`}
                                                id="full-name"
                                                autoComplete='true'
                                                value={shipping.name}
                                                name="fullName"
                                                type="text"
                                                onChange={(e) => this.onShippingChange('name', e.target.value)}
                                                onBlur={(e) => this.validateShipping('name', e.target.value)}
                                            />
                                        </div>
                                        <div className="modal-input-container full">
                                            <label className={`modal-label ${addressValid ? '' : 'invalid'}`}>Address:</label>
                                            <input
                                                className={`modal-input ${addressValid ? '' : 'invalid'}`}
                                                id="modal-line1"
                                                autoComplete="true"
                                                value={shipping.address}
                                                type="text"
                                                name="address"
                                                onBlur={(e) => this.validateShipping('address', e.target.value)}
                                                onChange={(e) => this.onShippingChange('address', e.target.value)}
                                            />
                                        </div>
                                        <div className="modal-input-container">
                                            <label className={`modal-label ${cityValid ? '' : 'invalid'}`}>City:</label>
                                            <input
                                                className={`modal-input ${cityValid ? '' : 'invalid'}`}
                                                id="modal-city"
                                                autoComplete="true"
                                                value={shipping.city}
                                                type="text"
                                                name="city"
                                                onBlur={(e) => this.validateShipping('city', e.target.value)}
                                                onChange={(e) => this.onShippingChange('city', e.target.value)}
                                            />
                                        </div>
                                        <div className="modal-input-container">
                                            <label className="modal-label">State:</label>
                                            <select
                                                className={`modal-input${stateValid ? "" : " invalid"}`}
                                                id="modal-state"
                                                value={shipping.state}
                                                onBlur={(e) => this.validateShipping('state', e.target.value)}
                                                onChange={e => this.onShippingChange('state', e.currentTarget.value)}
                                            >
                                                {states}
                                            </select>
                                        </div>
                                        <div className="modal-input-container">
                                            <label className={`modal-label ${zipcodeValid ? '' : 'invalid'}`}>Zipcode:</label>
                                            <input
                                                className={`modal-input ${zipcodeValid ? '' : 'invalid'}`}
                                                value={shipping.zipcode}
                                                id="zipcode"
                                                autoComplete="true"
                                                type="number"
                                                name="zipcode"
                                                onBlur={(e) => this.validateShipping('zipcode', e.target.value)}
                                                onChange={(e) => this.onShippingChange('zipcode', e.target.value)}
                                            />
                                        </div>
                                        <div className="modal-input-container full">
                                            <label className={`modal-label`}>Delivery Instructions:</label>
                                            <textarea
                                                className={`modal-input`}
                                                value={shipping.deliveryInstructions}
                                                id="checkout-delivery-instructions"
                                                type="text"
                                                onChange={(e) => this.onShippingChange('deliveryInstructions', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {this.renderError()}
                            </div>
                            <div className="cancel-modal-button-row">
                                <button onClick={this.cancelShippingEdits} className="dashboard-btn dashboard-submit white">Cancel</button>
                                <button onClick={this.updateShipping} className="dashboard-btn dashboard-save">Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderWorkEmailError() {
        const { workEmailValid, workEmailError } = this.state;
        if (!workEmailValid && workEmailError) {
            return (
                <FormError copy={workEmailError} />
            )
        }
    }

    renderError() {
        const {
            nameValid,
            addressValid,
            cityValid,
            stateValid,
            zipcodeValid,
            firstNameValid,
            lastNameValid,
            emailValid,
            phoneValid
        } = this.state;

        const fields = [
            nameValid,
            addressValid,
            cityValid,
            stateValid,
            zipcodeValid,
            firstNameValid,
            lastNameValid,
            emailValid,
            phoneValid
        ];
        let errors = 0;
        fields.forEach(field => {
            if (!field) errors++;
        })
        if (errors) {
            if (errors > 1) {
                return <ErrorMessage body="Please provide valid answers for all required fields" />
            } else {
                if (!nameValid) return <ErrorMessage body="Please enter valid name" />
                if (!addressValid) return <ErrorMessage body="Please enter valid address" />
                if (!cityValid) return <ErrorMessage body="Please enter valid city" />
                if (!stateValid) return <ErrorMessage body="Please select a state" />
                if (!zipcodeValid) return <ErrorMessage body="Please enter valid zipcode" />
                if (!firstNameValid) return <ErrorMessage body="Please enter valid first name" />
                if (!lastNameValid) return <ErrorMessage body="Please enter valid last name" />
                if (!emailValid) return <ErrorMessage body="Please enter valid email" />
                if (!phoneValid) return <ErrorMessage body="Please enter valid phone number" />
            }
        }
    }

    renderBilling() {
        const {
            user,
        } = this.state;

        if (user) {
            const { customer } = user;
            const { paymentSource } = customer;
            if (paymentSource !== undefined) {
                return (
                    <P key='billing'>
                        {paymentSource.brand} ending in {paymentSource.last4}<br />
                        Exp. {paymentSource.expirationMonth}/{paymentSource.expirationYear}
                    </P>
                )
            }
        }
    }

    renderBillingModal() {
        const {
            billingEdit,
            user,
            states,
            updating,
            order
        } = this.state;

        if (billingEdit && !updating) {
            return (
                <Elements>
                    <StripeForm
                        order={order}
                        customer={user.customer}
                        editing={billingEdit}
                        states={states}
                        cancel={this.cancelBillingEdits}
                        updateSource={this.updateBilling}
                        attemptRecharge={this.attemptRecharge}
                    />
                </Elements>
            )
        }
    }

    renderWorkDetails() {
        const { customer } = this.state;
        if (customer && customer.employer) {
            const { employer, workEmail } = customer;
            const { displayName } = employer;
            return (
                <P>
                    {displayName}<br />
                    {workEmail}
                </P>
            )
        }
    }

    renderWorkDetailsModal() {
        const {
            workEdit,
            workEmail,
            workEmailValid,
            updating,
            employer
        } = this.state;

        if (workEdit && employer && !updating) {
            const { displayName } = employer;
            // TODO: DRY this page up!
            return (
                <div id="non-account-modal-container" className="modal-container transparent">
                    <div className="interview-question-modal">
                        <img onClick={this.cancelWorkEdits} className="raised text-right order-x-icon" src={images.btn_close_x} alt="close" />
                        <div className="interview-modal-body$ no-padding">
                            <div className="signin-modal-tab-container">
                                <div className="cancel-modal-body">
                                    <p className="modal-title">
                                        Update Work Info
                                        </p>
                                    <hr />
                                    <div className="input-wrapper flex-row flex-wrap account-modal">
                                        <div className="modal-input-container">
                                            <label className={`modal-label ${workEmailValid ? '' : 'invalid'}`}>Work Email:</label>
                                            <input
                                                autoFocus
                                                className={`modal-input ${workEmailValid ? '' : 'invalid'}`}
                                                autoComplete="email"
                                                name="workEmail"
                                                value={workEmail}
                                                type="email"
                                                onChange={(e) => this.onDetailsChange('workEmail', e.target.value)}
                                                onBlur={(e) => this.validateDetails('workEmail', e.target.value)}
                                            />
                                        </div>
                                        <div className="modal-input-container">
                                            <label className="modal-label">Employer:</label>
                                            <div id="employer-field">{displayName}</div>
                                        </div>
                                    </div>
                                    {this.renderWorkEmailError()}
                                    <p className="mobile-note space-top">
                                        By clicking Save Changes you confirm you are currently employed by {displayName} and that at any time you are no longer employed by {displayName} or {displayName} no longer supplements your Supply Drop subscription, that you will be responsible for the entire cost of your plan.
                                    </p>
                                </div>
                            </div>
                            <div className="cancel-modal-button-row">
                                <button onClick={this.cancelWorkEdits} className="dashboard-btn dashboard-submit white">Cancel</button>
                                <button disabled={!workEmailValid || !workEmail} onClick={this.updateEnrollment} className="dashboard-btn dashboard-save">Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderPersonalDetailsModal() {
        const {
            detailsEdit,
            user,
            firstName,
            lastName,
            email,
            phone,
            firstNameValid,
            lastNameValid,
            emailValid,
            updating,
            phoneValid,
        } = this.state;
        if (user) {
            if (detailsEdit && !updating) {
                return (
                    <div id="non-account-modal-container" className="modal-container transparent">
                        <div className="interview-question-modal">
                            <img onClick={this.cancelDetailsEdits} className="raised text-right order-x-icon" src={images.btn_close_x} alt="close" />
                            <div className="interview-modal-body$ no-padding">
                                <div className="signin-modal-tab-container">
                                    <div className="cancel-modal-body">
                                        <p className="modal-title">
                                            Update Personal Info
                                        </p>
                                        <hr />
                                        <div className="input-wrapper flex-row flex-wrap account-modal">
                                            <div className="modal-input-container">
                                                <label className={`modal-label ${firstNameValid ? '' : 'invalid'}`}>First Name:</label>
                                                <input
                                                    className={`modal-input ${firstNameValid ? '' : 'invalid'}`}
                                                    autoComplete="true"
                                                    name="firstName"
                                                    value={firstName}
                                                    type="text"
                                                    onChange={(e) => this.onDetailsChange('firstName', e.target.value)}
                                                    onBlur={(e) => this.validateDetails('firstName', e.target.value)}
                                                />
                                            </div>
                                            <div className="modal-input-container">
                                                <label className={`modal-label ${lastNameValid ? '' : 'invalid'}`}>Last Name:</label>
                                                <input
                                                    className={`modal-input ${lastNameValid ? '' : 'invalid'}`}
                                                    value={lastName}
                                                    autoComplete="true"
                                                    name="lastName"
                                                    type="text"
                                                    onBlur={(e) => this.validateDetails('lastName', e.target.value)}
                                                    onChange={(e) => this.onDetailsChange('lastName', e.target.value)}
                                                />
                                            </div>
                                            <div className="modal-input-container">
                                                <label className={`modal-label ${emailValid ? '' : 'invalid'}`}>Email:</label>
                                                <input
                                                    className={`modal-input ${emailValid ? '' : 'invalid'}`}
                                                    autoComplete="true"
                                                    value={email}
                                                    type="email"
                                                    name="email"
                                                    onBlur={(e) => this.validateDetails('email', e.target.value)}
                                                    onChange={(e) => this.onDetailsChange('email', e.target.value)}
                                                />
                                            </div>
                                            <div className="modal-input-container">
                                                <label className={`modal-label ${phoneValid ? '' : 'invalid'}`}>Phone:</label>
                                                <input
                                                    className={`modal-input ${phoneValid ? '' : 'invalid'}`}
                                                    type="tel"
                                                    name="phone"
                                                    autoComplete="home tel-national"
                                                    placeholder="Mobile number"
                                                    value={phone}
                                                    onBlur={(e) => this.validateDetails('phone', e.target.value)}
                                                    onChange={(e) => this.onDetailsChange('phone', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {this.renderError()}
                                </div>
                                <div className="cancel-modal-button-row">
                                    <button onClick={this.cancelDetailsEdits} className="dashboard-btn dashboard-submit white">Cancel</button>
                                    <button onClick={this.updateDetails} className="dashboard-btn dashboard-save">Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    renderPersonalDetails() {
        const {
            user,
            phone,
        } = this.state;
        if (user) {
            const { customer } = user;
            const {
                firstName,
                lastName,
                email
            } = customer;

            return (
                <P key='personalDetails'>
                    {firstName} {lastName}<br />
                    {phone}<br />
                    {email}
                </P>
            )
        }
    }

    renderSubsections(subsections) {
        const rows = [];
        for (let i = 0; i < subsections.length; i++) {
            const { title, cta, onclick, body } = subsections[i];
            if (title || cta) {
                rows.push(
                    <div key={title} className="dashboard-section-header-row">
                        <div className="account-section-subheader">{title}</div>
                        <div className="account-section-edit" onClick={onclick}>{cta}</div>
                    </div>
                )
            }
            rows.push(body)
            if (i + 1 < subsections.length) {
                rows.push(<hr key={i} />)
            }
        }

        return (
            <div className="dashboard-section-container">
                {rows}
            </div>
        )
    }

    renderSection(title, subsections) {
        return (
            <Fragment>
                <div className="account-section-header-container"><h4>{title}</h4></div>
                {this.renderSubsections(subsections)}
            </Fragment>
        )
    }

    renderCustomer() {
        const { user, order, employer } = this.state;
        if (user && order) {
            const shipDate = moment(order.expectedShipDate);
            const isShipped = moment().isBefore(shipDate);
            const shipDateString = moment(shipDate).format('ll')
            const deliveryString =
                `${isShipped ? `Next delivery will be sent on ${shipDateString}` :
                    `Last delivery sent on ${shipDateString}`} `


            const accountSubsections = [
                {
                    title: "Personal Info",
                    cta: "Update Info",
                    onclick: () => this.setToEdit('detailsEdit'),
                    body: this.renderPersonalDetails()
                }
            ]
            if (employer)
                accountSubsections.push({
                    title: "Work Info",
                    cta: "Update Info",
                    onclick: () => this.setToEdit('workEdit'),
                    body: this.renderWorkDetails()
                })
            const householdSubsections = [{
                title: "",
                cta: "Edit Household",
                onclick: this.setToHouseholdEdit,
                body: this.renderHousehold()
            }]
            const paymentSubsections = [{
                title: "Payment Info",
                cta: "Update Info",
                onclick: () => this.setToEdit('billingEdit'),
                body: this.renderBilling()
            }]
            const deliverySubsections = [
                {
                    title: "Delivery Info",
                    cta: "Update Info",
                    onclick: () => this.setToEdit('shippingEdit'),
                    body: this.renderShippingSection()
                },
                {
                    title: "Order History",
                    cta: "View History",
                    onclick: () => this.props.history.push('/dashboard/orders'),
                    body: (<P key={shipDate}>{deliveryString}</P>)
                },
                {
                    body: (
                        <div key='pauseOrCancel' className="account-section-edit text-center no-margin" onClick={this.togglePauseModal}>Pause or Cancel My Subscription</div>
                    )
                }
            ]
            return (
                <div className="account-sections-container">
                    {this.renderSection('Account Info', accountSubsections)}
                    {this.renderSection('Household', householdSubsections)}
                    {this.renderSection('Payment', paymentSubsections)}
                    {this.renderSection('Delivery', deliverySubsections)}
                </div>
            )
        } else {
            return (
                <div>
                    <Loading />
                </div>
            )
        }
    }

    renderHousehold() {
				const { household } = this.state;
        if (household) {
            const { family, pets } = household;
            const icons = family.map((member,i) => {
                const { gender, age, type } = member;
                return (
                    <div key={`household-member${i}`} onClick={this.setToHouseholdEdit}>
                        <div className="interview-household-img account-dash"><img className={type.toLowerCase()} src={images[`${gender.toLowerCase()}_${type.toLowerCase()}`]} alt={`${type} ${gender}`} /></div>
                        <div className="stays-home-age text-center">{age}</div>
                    </div>
                )
            })

            const dogs = pets.Dog + pets.Puppy;
            const cats = pets.Cat + pets.Kitten;

            for (let dog = 0; dog < dogs; dog++) {
                icons.push(
                    <div key='dog' onClick={this.setToHouseholdEdit}>
                        <div className="interview-household-img account-dash">
                            <img className="pet" src={images.dog} alt="Dog" />
                        </div>
                    </div>
                )
            }

            for (let cat = 0; cat < cats; cat++) {
                icons.push(
                    <div key='cat' onClick={this.setToHouseholdEdit}>
                        <div className="interview-household-img account-dash">
                            <img className="pet" src={images.cat} alt="Cat" />
                        </div>
                    </div>
                )
            }

            return (
                <div key="householdContainer" className="account-household-container">
                    {icons}
                </div>
            )
        }
    }

    renderPhoneBanner() {
        const { isUnverified, customer, limitExceeded } = this.state;
        if (isUnverified && customer && !limitExceeded) {
            return <PhoneBanner next={() => this.setState({ showVerificationModal: true })} />
        }
    }

    renderPhoneModal() {
        const { showVerificationModal, isUnverified, customer, limitExceeded } = this.state;
        if (isUnverified && showVerificationModal && customer && !limitExceeded) {
            return (
                <PhoneVerification
                    isModal={true}
                    limitExceeded={() => this.setState({ limitExceeded: true, showVerificationModal: false })}
                    email={customer.email}
                    phone={customer.phone}
                    setToVerified={() => this.setState({ isUnverified: false })}
                    close={() => this.setState({ showVerificationModal: false })}
                />
            )
        }
    }

    renderHouseholdModal() {
        const { householdEdit, household } = this.state;
        if (householdEdit) {
            return <Household
                isModal={true}
                next={this.updateInterview}
                back={this.resetHousehold}
                close={this.resetHousehold}
                history={this.props.history}
                household={household}
                cancelText="Cancel"
                continueText="Save"
            />
        }
    }

    renderMain() {
        const { signedIn, isUnverified, limitExceeded } = this.state;
        if (signedIn) {
            return (
                <Fragment>
                    {this.renderPhoneBanner()}
                    <div className={`account-header-container${isUnverified && !limitExceeded ? " with-banner" : ""}`}>
                        <img src={images.accountHeader} alt="My Account" />
                        <div id="account-header">My Account</div>
                    </div>
                    {this.renderCustomer()}
                    {this.renderShippingModal()}
                    {this.renderBillingModal()}
                    {this.renderHouseholdModal()}
                    {this.renderWorkDetailsModal()}
                    {this.renderPersonalDetailsModal()}
                    {this.renderPauseModal()}
                    {this.renderPhoneModal()}
                </Fragment>
            )
        } else {
            return <Loading />
        }
    }

    render() {
        const { isUnverified, dashboardData } = this.state;
        return (
            <div className="no-cloud">
                <MetaTags title="Supply Drop - Dashboard - Account" />
                {this.renderPutModal()}
                {this.renderHouseholdUpdatedModal()}
                <Menu history={this.props.history} dashboardData={{ ...dashboardData, verified: !isUnverified }} />
                <div className="dashboard-body-container">
                    {this.renderMain()}
                </div>
                {/* TODO: refactor footer for Dashboard components */}
                {/* <Footer /> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        user,
        error,
        updateCustomerSuccess,
        updateCustomerError,
        updatingCustomer,
        putSourceSuccess,
        putSourceError,
        updatingSource,
        enrollingCustomer,
        enrollCustomerSuccess,
        enrollCustomerError,
        magicLink,
        magicLinkErr,
        gettingMagicLink
    } = state.user;
    const {
        orders,
        getOrdersError,
        landingData,
        gettingLandingData,
        landingError,
        rechargeSuccess,
        rechargeError,
    } = state.order;

    const {
        signedInAuthErr,
        nonSignedInAuthErr
    } = state.globalErrors;

    return {
        user,
        error,
        updateCustomerSuccess,
        updateCustomerError,
        updatingCustomer,
        putSourceSuccess,
        putSourceError,
        updatingSource,
        orders,
        getOrdersError,
        landingData,
        gettingLandingData,
        landingError,
        enrollingCustomer,
        enrollCustomerSuccess,
        enrollCustomerError,
        rechargeSuccess,
        rechargeError,
        signedInAuthErr,
        nonSignedInAuthErr,
        magicLink,
        magicLinkErr,
        gettingMagicLink
    }
}


export default connect(mapStateToProps, {
    clearCustomerState,
    createGlobalAuthError,
    clearOrdersState,
    enrollCustomer,
    getLanding,
    getMagicLink,
    getOrders,
    getUser,
    putInterview,
    recharge,
    updateCustomer,
    updateSource,
})(Account);
