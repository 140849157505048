import React, { Component, Fragment } from 'react';
import { InterviewQuestion, HouseholdButton } from '../../Common';
import * as images from '../../../assets';
import { Alert } from 'react-bootstrap';
import { marginForCountdown } from '../../../actions';

class StaysHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isolated: false,
            infoCopy: (
                <div>
                    The amount of time your family spends in the house directly affects how many supplies you use.
                </div>
            ),
            image: {
                src: images.house,
                alt: "Household"
            }
        };

        this.toggleStaysHome = this.toggleStaysHome.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.renderFamily = this.renderFamily.bind(this);
        this.renderCovidBox = this.renderCovidBox.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
        this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (this.props.location && this.props.location.state)
            this.setSignUpData(this.props.location.state.signUpData);
    }

    setSignUpData(signUpData) {
        try {
            this.setState({ signUpData })
            this.setStateForKeyValue(signUpData);
            const { interview, zipcode } = signUpData;
            if (interview) {
                this.setStateForKeyValue(interview);
                const { family } = interview;
                const isolated = family.length ? family[0].isolated : false;
                this.setState({ family, isolated });

                this.setState({ interview })
            } else {
                this.back();
            }

            if (zipcode)
                marginForCountdown();
        } catch (err) {
            console.error(err);
        }
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    back() {
        const { signUpData } = this.state;
        this.props.history.push('/start/1', { signUpData })
    }

    next() {
        const {
            family,
            isolated,
            interview,
            signUpData
        } = this.state;

        family.forEach(member => {
            member.isolated = !!isolated;
        })

        const newFamily = family.map(member => {
            const { gender, age, staysHome, isolated, type } = member
            return { gender, age, staysHome, isolated, type }
        });
        interview.family = newFamily

        this.props.history.push('/start/3', { signUpData });
    }

    toggleStaysHome(id) {
        const { family } = this.state;
        const member = family.filter(m => m.id === id)[0];
        const newFamily = family.filter(m => m.id !== id);
        member.staysHome = !member.staysHome;

        newFamily.push(member);
        newFamily.sort((a, b) => {
            return a.id - b.id;
        });

        this.setState({ family: newFamily });
    }

    renderFamily() {
        const { family } = this.state;
        if (family) {
            const rows = family.map(member => {
                const { age, type, gender, id, staysHome } = member;
                return (
                    <HouseholdButton
                        key={id}
                        id={id}
                        age={age}
                        type={type}
                        gender={gender}
                        staysHome={staysHome}
                        onclick={() => this.toggleStaysHome(id)}
                    />
                )
                // <div className="interview-household-btn" >
                //     <div className="checkbox-container">
                //         <div className={`checkbox${member.staysHome ? " checked" : ""}`} >
                //             <img src={images.checkmark_white} alt="checkbox" className={member.staysHome ? "checkmark-white" : "hide"} />
                //         </div>
                //     </div>
                //     <img src={images[`${member.gender.toLowerCase()}_${member.type.toLowerCase()}`]} alt={`${member.gender} ${member.type}`} className="stays-home-member-img" />
                //     <div className="stays-home-age">{member.age}</div>
                // </div>
            })
            return (
                <div className="household-icons-row stays-home">
                    {rows}
                </div>

            )
        }
    }

    renderCovidBox() {
        const { isolated } = this.state;

        const notice = () => {
            if (isolated) {
                return (
                    <p className="covid-notification">
                        Please continue to answer this question as<br /> you would under normal circumstances.
                    </p>
                )
            }
        }
        return (
            <Fragment>
                <Alert className="covid-alert" onClick={() => this.setState({ isolated: !isolated })}>
                    <div className="checkbox-container">
                        <div className={`checkbox${isolated ? " checked" : ""}`} >
                            <img src={images.checkmark_white} alt="checkbox" className={isolated ? "checkmark-white" : "hide"} />
                        </div>
                    </div>
                    <div>Are you currently staying home due to COVID-19?</div>
                </Alert>
                {notice()}
            </Fragment>
        )
    }

    render() {
        const { image, infoCopy, signUpData } = this.state;
        const { isModal, history } = this.props;
        return (
            <InterviewQuestion
                key="StaysHome"
                id="StaysHome"
                back={this.back}
                next={this.next}
                history={history}
                notification={this.renderCovidBox}
                image={image}
                question={
                    <Fragment>
                        Who leaves for work or school most<br />days of the week?
                    </Fragment>
                }
                isModal={isModal}
                infoCopy={infoCopy}
                body={this.renderFamily}
                zipcode={this.state.zipcode}
                setZipcode={(zipcode) => this.setState({ zipcode })}
                signUpData={signUpData}
            />
        )
    }
}

export default StaysHome;