import { isEnabled } from './helpers/flagHelper';

export default {
    supplyDropEnvironment: process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT,
    apiUrl: process.env.REACT_APP_API_URL,
    apiHostName: process.env.REACT_APP_API_HOST_NAME,
    stripeKey: process.env.REACT_APP_STRIPE_KEY,
    gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
    gaAdTrackingId: process.env.REACT_APP_GA_AD_TRACKING_ID,
    gaAdConversionTrackingId: process.env.REACT_APP_GA_AD_CONVERSION_TRACKING_ID,
    pinpointAppId: process.env.REACT_APP_PINPOINT_APP_ID,
    dataDogRUM: {
      clientToken: process.env.REACT_APP_DATA_DOG_RUM_CLIENT_TOKEN,
      applicationId: process.env.REACT_APP_DATA_DOG_RUM_APPLICATION_ID,
    },
    sentryEnv: process.env.REACT_APP_SENTRY_ENV,
    gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
    experiments: {}
  }
  
export const featureFlags = {
  isUngated: isEnabled(process.env.REACT_APP_IS_UNGATED),
  recommendation_page: isEnabled(process.env.REACT_APP_RECOMMENDATION_PAGE),
  use_mock_rounder: isEnabled(process.env.REACT_APP_MOCK_ROUNDER),
  block_signups: isEnabled(process.env.REACT_APP_BLOCK_SIGNUPS),
  block_outside_texas: isEnabled(process.env.REACT_APP_BLOCK_OUTSIDE_TX),
  google_form: isEnabled(process.env.REACT_APP_GOOGLE_FORM),
  material_stripe_form: isEnabled(process.env.REACT_APP_MATERIAL_STRIPE_FORM),
  material_checkout_form: isEnabled(process.env.REACT_APP_MATERIAL_CHECKOUT_FORM),
  recommendation_styled_dashboard: isEnabled(process.env.REACT_APP_RECOMMENDATION_STYLED_DASHBOARD)
}
