import React, { Component, Fragment } from 'react';
import * as Sentry from '@sentry/browser';
import { Row, Col } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { PastOrderTotalHR as HR } from '../../../Style/PastOrders';

class PastOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    renderOrder() {
        const { order, customer, productsMap } = this.props;
        if (order) {

            const {
                orderId,
                expectedDeliveryDate,
                totalCents,
                subtotalCents,
                discounts,
                taxes,
                fees
                // TODO: Handle refunds
                // refunds
            } = order;
            try {
                return (
                    <div>
                        <Row className="order-row">
                            <Col xs={6} className="order-label">
                                <div>{moment(expectedDeliveryDate).format('LL')}</div>
                                <Link to={{ pathname: `order/${orderId}/`, state: { productsMap, order, customer } }}>View Receipt</Link>
                            </Col>
                            <Col xs={6} className="text-right order-label">
                                <div className="order-total">
                                    ${(totalCents / 100).toFixed(2)}
                                    <svg data-tip data-for={"manifest-total-" + expectedDeliveryDate} width="16" height="16" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="interview-icon interview-info-icon">
                                        <circle className="info-circle" cx="10.5" cy="10" r="9.5" stroke="#183041" stroke-opacity="0.8" />
                                        <path d="M13.5387 7.92904C13.5387 8.25456 13.491 8.54536 13.3955 8.80143C13.3 9.05751 13.1633 9.29405 12.9854 9.51107C12.8074 9.72808 12.5166 9.98416 12.113 10.2793L11.5921 10.6634C11.2796 10.8891 11.0474 11.1278 10.8955 11.3796C10.7436 11.627 10.6655 11.8982 10.6611 12.1934H9.52181C9.53049 11.8939 9.57606 11.6313 9.65853 11.4056C9.74533 11.1799 9.85601 10.9803 9.99056 10.8066C10.1251 10.633 10.277 10.479 10.4463 10.3444C10.6156 10.2055 10.787 10.0753 10.9606 9.95378C11.1342 9.82791 11.3035 9.70421 11.4684 9.58268C11.6377 9.45681 11.7874 9.31793 11.9176 9.16602C12.0479 9.01411 12.152 8.84266 12.2301 8.65169C12.3126 8.46072 12.3538 8.2372 12.3538 7.98112C12.3538 7.48633 12.1846 7.0957 11.846 6.80924C11.5118 6.52279 11.0409 6.37956 10.4333 6.37956C9.82563 6.37956 9.34386 6.53147 8.98796 6.83529C8.63205 7.13911 8.42372 7.55577 8.36296 8.08529L7.16504 8.00716C7.27789 7.14779 7.61643 6.48806 8.18066 6.02799C8.7449 5.56793 9.49143 5.33789 10.4202 5.33789C11.3881 5.33789 12.1498 5.56793 12.7054 6.02799C13.261 6.48372 13.5387 7.1174 13.5387 7.92904ZM9.46973 14.6478V13.3392H10.7393V14.6478H9.46973Z" fill="#183041" fillOpacity="0.8" />
                                    </svg>
                                    <ReactTooltip
                                        id={"manifest-total-" + expectedDeliveryDate}
                                        className="manifest-total"
                                        place="bottom"
                                        effect="solid"
                                    >
                                        <div id="manifest-total-header">What's included?</div>
                                        <div className="manifest-total-row">Subtotal = ${(subtotalCents / 100).toFixed(2)}</div>
                                        <HR />
                                        {this.renderDiscounts(discounts)}
                                        {this.renderFees(fees)}
                                        {this.renderTaxes(taxes)}
                                    </ReactTooltip>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )
            } catch (err) {
                Sentry.captureException(err);
                console.error(err);
            }
        }
    }

    renderTaxes(taxes) {
        if (taxes && taxes.length) {
            let taxCents = 0;
            taxes.forEach(tax => {
                taxCents += tax.amountCents;
            })
            return (
                <Fragment>
                    <div className="manifest-total-row">Tax = ${(taxCents / 100).toFixed(2)}</div>
                </Fragment>
            )
        }
    }

    renderFees(fees) {
        if (fees && fees.length) {
            let feeCents = 0;
            fees.forEach(fee => {
                feeCents += fee.amountCents;
            })
            return (
                <Fragment>
                    <div className="manifest-total-row">Fees = ${(feeCents / 100).toFixed(2)}</div>
                    <HR />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div className="manifest-total-row">Shipping = Free</div>
                    <HR />
                </Fragment>
            )
        }
    }

    renderDiscounts(discounts) {
        // TODO: Handle multiple coupons
        if (discounts && discounts.length) {
            const discount = discounts[0];
            return (
                <Fragment>
                    <div className="manifest-total-row green">Discount = -${(Math.abs(discount.amountCents) / 100).toFixed(2)}</div>
                    <HR />
                </Fragment>
            )
        }
    }

    render() {
        return (
            <div>
                {this.renderOrder()}
            </div>
        )
    }
}

export default PastOrder;
