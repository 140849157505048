import React, { Fragment } from 'react';
import { InterviewQuestion, Counter, ProductDetail } from '../../Common';
import * as images from '../../../assets';
import { marginForCountdown, getProductGroups } from '../../../actions';
import { connect } from 'react-redux';
import * as Sentry from "@sentry/browser";

class TrashPreferences extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			infoCopy: (
				<div>
					Your first month, we’ll send you a starter pack of 40 trash bags.
					Then, based on how many trash bags you use a week, we’ll calculate when to send you more.
				</div>
			),
			image: {
				src: images.trashbag,
				alt: "Trashbag"
			},
			sortedTrashTypes: [
				"Kitchen",
				"Recycling",
				"Small",
				"Compost",
				"Outdoor"
			],
			trashTypes: {
				Kitchen: {
					displayName: "Kitchen",
					min: 0,
					frequency: 1
				},
				Recycling: {
					displayName: "Recycle",
					min: 0,
					frequency: 0
				},
				Small: {
					displayName: "Waste Basket",
					min: 0,
					frequency: 0
				},
				Outdoor: {
					displayName: "Outdoor",
					min: 0,
					frequency: 0,
				},
				Compost: {
					displayName: "Compost",
					min: 0,
					frequency: 0
				}
			}
		};

		this.increment = this.increment.bind(this);
		this.decrement = this.decrement.bind(this);
		this.next = this.next.bind(this);
		this.back = this.back.bind(this);
		this.renderBody = this.renderBody.bind(this);
		this.renderCounter = this.renderCounter.bind(this);
		this.renderProductDetails = this.renderProductDetails.bind(this);
		this.setTypesAndProductsMap = this.setTypesAndProductsMap.bind(this);
		this.setTypesMap = this.setTypesMap.bind(this);
		this.setSignUpData = this.setSignUpData.bind(this);
		this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		if (this.props.location && this.props.location.state)
			this.setSignUpData(this.props.location.state.signUpData);
	}

	setSignUpData(signUpData) {
		try {
			this.setState({signUpData})
			this.setStateForKeyValue(signUpData);
			const { interview, productGroupsMap, zipcode } = signUpData;
			if (interview) {
				this.setStateForKeyValue(interview);
				if (interview.trashPreferences) {
					const { trashTypes } = this.state;
					interview.trashPreferences.forEach(pref => {
						const { frequency, type } = pref;
						trashTypes[type].frequency = frequency
					})
					this.setState({ trashTypes })
				}
				if (productGroupsMap) {
					this.setTypesMap(productGroupsMap);
				} else this.props.getProductGroups();
			} else {
				this.back();
			}

			if (zipcode)
				marginForCountdown();
		} catch (err) {
			console.error(err);
		}
	}

	setStateForKeyValue(data) {
		try {
			Object.entries(data).forEach(entry => {
				const key = entry[0], value = entry[1];
				if (value !== undefined) {
					this.setState({ [key]: value })
				}
			})
		} catch (err) {
			console.error(err);
		}
	}

	componentDidUpdate() {
		const { productGroups } = this.props;
		if (productGroups && !this.state.productGroups) {
			this.setState({ productGroups });
			this.setTypesAndProductsMap(productGroups)
		}

		if(this.state.productGroups && !this.state.productGroupsMap) {
			this.setTypesAndProductsMap(this.state.productGroups)
		}
	}

	setTypesAndProductsMap(productGroups) {
		try {
			const productGroupsMap = {};
			const typesMap = {};
			productGroups.forEach(pg => {
				productGroupsMap[pg.groupName] = pg;
				if (pg.status === "Active") {
					typesMap[pg.productType] = pg;
				}
			})
			this.setState({ productGroupsMap, typesMap })
		} catch (err) {
			console.error(err)
			Sentry.captureException(err)
		}
	}

	setTypesMap(productGroupsMap) {
		try {
			const typesMap = {};
			Object.values(productGroupsMap).forEach(pg => {
				if (pg.status === "Active")
					typesMap[pg.productType] = pg;
			})
			this.setState({ typesMap })
		} catch (err) {
			console.error(err)
			Sentry.captureException(err)
		}
	}

	renderProductDetails() {
		const { showProduct } = this.state;
		if (showProduct)
			return <ProductDetail productGroup={showProduct} close={() => { this.setState({ showProduct: undefined }) }} />
	}

	back() {
		const { signUpData } = this.state;
		this.props.history.push('/start/3', { signUpData })
	}

	next() {
		const {
			trashTypes,
			interview,
			productGroupsMap,
			signUpData,
			productGroups
		} = this.state;

		if (productGroupsMap && productGroups) {
			interview.trashPreferences = Object.entries(trashTypes).map(entry => {
				return {
					type: entry[0],
					frequency: entry[1].frequency,
					bags: 1
				}
			})

			const updateSignUpData = Object.assign(signUpData, { productGroups, productGroupsMap, interview, trashTypes });

			this.props.history.push('/kitchen/1', { signUpData: updateSignUpData })
		}
	}

	decrement(trashType) {
		const { trashTypes } = this.state;
		const stateType = trashTypes[trashType];
		if (stateType.frequency) stateType.frequency -= 1;
		trashTypes[trashType] = stateType
		this.setState({ trashTypes })
	}

	increment(trashType) {
		const { trashTypes } = this.state;
		const stateType = trashTypes[trashType];
		stateType.frequency++;
		trashTypes[trashType] = stateType
		this.setState({ trashTypes })
	}

	renderCounter(trashType) {
		const { trashTypes } = this.state;
		return (
			<Counter count={trashTypes[trashType].frequency} min={trashTypes[trashType].min} decrement={() => this.decrement(trashType)} increment={() => this.increment(trashType)} type="trash interview" />
		)
	}

	renderBody() {
		const { trashTypes, sortedTrashTypes, typesMap } = this.state;
		if (typesMap) {
			const body = [];
			sortedTrashTypes.forEach(trashType => {
				if (typesMap[`TrashBags${trashType}`]) {
					const { displayName } = trashTypes[trashType];
					body.push(
						<div key={trashType} className="trash-row-wrapper">
							<div className="trash-icon">
								<img src={images[`${trashType.toLowerCase()}_trash_icon`]} alt={trashType} />
								<svg className="trash-info-icon interview-icon" onClick={() => this.setState({ showProduct: typesMap[`TrashBags${trashType}`] })} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle className="info-circle" cx="7.5" cy="7.5" r="7" stroke="#183041" strokeOpacity="0.8" />
									<circle className="info-dot" cx="7.49902" cy="4.5" r="0.75" fill="#183041" fillOpacity="0.8" />
									<rect width="1.5" height="5.25" rx="0.75" transform="matrix(-1 0 0 1 8.25098 6.00049)" fill="#183041" fillOpacity="0.8" />
								</svg>
								<p>{displayName}</p>
							</div>
							{this.renderCounter(trashType)}
						</div>
					)
				}
			})
			return (
				// TODO: return counter for each trash type
				<div className="trash-container">
					{body}
				</div>
			)
		}
	}

	render() {
		const { image, infoCopy, showProduct, typesMap, signUpData } = this.state;
		const { isModal, history } = this.props

		return (
			<Fragment>
				<InterviewQuestion
					key="TrashPreferences"
					id="TrashPreferences"
					back={this.back}
					next={this.next}
					history={history}
					image={image}
					question="How many trash bags do you use every week?"
					title="Supply Drop - Personalization - Household"
					isModal={isModal}
					body={this.renderBody}
					infoCopy={infoCopy}
					showProduct={showProduct}
					closeProductDetails={() => this.setState({ showProduct: undefined })}
					disabled={!typesMap}
					zipcode={this.state.zipcode}
                	setZipcode={(zipcode) => this.setState({ zipcode })}
					signUpData={signUpData}
				/>
				{this.renderProductDetails()}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	const { productGroups, productGroupsError } = state.product;
	return { productGroups, productGroupsError }
}

export default connect(mapStateToProps, {
	getProductGroups
})(TrashPreferences);	
