import * as Sentry from '@sentry/browser';
import {
    bundleMap,
    bundles
} from '../data';
import ReactGA from 'react-ga';
import { Logger } from '../helpers/Logger';
const logger = new Logger()

export async function setCappedArticleCounts(productType, productGroups, productGroupsMap) {
    try {
        // cap - maximum product quantity factor beyond currently selected/predicted
        const cap = 6;
        const maxOptions = 12;
        Object.entries(productGroups).forEach(entry => {
            const groupName = entry[0];
            const { articles, articlesPerDay } = entry[1];
            if (productGroupsMap[groupName]) {
                if (articlesPerDay)
                    productGroupsMap[groupName].articlesPerDay = articlesPerDay;

                productGroupsMap[groupName].articles = articles;
                productGroupsMap[groupName].proposedArticles = articles;
                productGroupsMap[groupName].cappedArticleCounts = [];
                // May need a more graceful way to handle when the proposedArticles are 0
                // so that cappedArticleCounts isn't an empty set...
                // For now:
                const possibleArticleCounts = productGroupsMap[groupName].possibleArticleCounts
                if (articles !== undefined) {
                    if (!possibleArticleCounts.includes(articles))
                        possibleArticleCounts.push(articles);

                    possibleArticleCounts.sort((a, b) => {
                        return a - b;
                    });

                    // select up to maxOptions using the selectedArticle as the midpoint of cappedArticles to display to user
                    const selectedArticle = possibleArticleCounts.indexOf(articles);
                    productGroupsMap[groupName].cappedArticleCounts.push(articles);
                    for (var i=1; i < possibleArticleCounts.length && productGroupsMap[groupName].cappedArticleCounts.length < maxOptions; i++) {
                      var lowerIndex = selectedArticle - i;
                      var upperIndex = selectedArticle + i;
                      if (lowerIndex >= 0 ) {
                        productGroupsMap[groupName].cappedArticleCounts.push(possibleArticleCounts[lowerIndex])
                      }
                      if (upperIndex < possibleArticleCounts.length && possibleArticleCounts[upperIndex] < (articles * cap)) {
                        productGroupsMap[groupName].cappedArticleCounts.push(possibleArticleCounts[upperIndex])
                      }
                    }
                    
                    productGroupsMap[groupName].cappedArticleCounts.sort((a, b) => {
                        return a - b;
                    });
                } else {
                    if (possibleArticleCounts.length)
                        productGroupsMap[groupName].cappedArticleCounts.push(possibleArticleCounts[0]);
                }
            }
            else {
                console.log(productGroups, productGroupsMap)
                logger.error(new Error(`${groupName} not present in ProductGroupMap`))
            }
        })
        return productGroupsMap;
    } catch (err) {
        console.error(err);
        Sentry.captureException(err);
        return productGroupsMap;
    }
}

export async function refreshCappedValues(productSets, productGroupsMap) {
    try {
        // set cappedArticleCounts for all productGroups returned from rounder.productSets
        // whether or not that productType is selected by user
        for (let [productType, value] of Object.entries(productSets)) {
            const { preferred, additional } = value;
            const productGroups = await setProductGroups(productType, preferred, additional, null, false, productGroupsMap);
            productGroupsMap = await setCappedArticleCounts(productType, productGroups, productGroupsMap)
        }
        return ({ newProductsMap: productGroupsMap })
    } catch (err) {
        console.error(err);
        Sentry.captureException(err);
        return (err);
    }
}

export function setSubtotal(manifestBundles, bundleShortName) {
    try {
        let subtotal = 0;
        Object.values(manifestBundles[bundleShortName].productGroups).forEach(productGroup => {
            subtotal += productGroup.articles * productGroup.pricePerArticle;
        })
        manifestBundles[bundleShortName].subtotal = subtotal;
        return { manifestBundles }
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        return { error };
    }
}


export async function setManifest(rounder, productGroupsMap, selectedProductTypes, isDashboard) {
    try {
        const { productSets } = rounder;
        let productCount = 0;

        const manifestBundles = {
            everyday: {
                productGroups: [],
                subtotal: 0
            },
            kitchen: {
                productGroups: [],
                subtotal: 0
            },
            laundry: {
                productGroups: [],
                subtotal: 0
            },
            defense: {
                productGroups: [],
                subtotal: 0
            }
        };

        for (let [productType, value] of Object.entries(rounder.productSets)) {
            // set cappedArticleCounts for all productGroups returned from rounder.productSets
            // whether or not that productType is selected by user
            const { preferred, additional } = value
            const productGroups = await setProductGroups(productType, preferred, additional, selectedProductTypes, isDashboard, productGroupsMap);
            productGroupsMap = await setCappedArticleCounts(productType, productGroups, productGroupsMap)

            if (selectedProductTypes[productType]) {
                const { groupName, articles } = await setToSelectedProductTypes(preferred, selectedProductTypes[productType], isDashboard)

                const shortName = bundleMap[productType];
                productGroupsMap[groupName].articles = articles;

                manifestBundles[shortName].subtotal += (articles * productGroupsMap[groupName].pricePerArticle);
                manifestBundles[shortName].productGroups[productType] = productGroupsMap[groupName];
                productCount++;
                if (Object.values(manifestBundles[shortName].productGroups).length === bundles[shortName].productTypes.length)
                    manifestBundles[shortName].hasAll = true;
            }
        }
        return ({ rounderSet: productSets, manifestBundles, productGroupsMap, productCount })
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        return { error }
    }
}

function setToSelectedProductTypes(rounderGroup, selectedGroup, isDashboard) {
    try {
        if (isDashboard) {
            const { groupName, articleCount } = selectedGroup;
            return { groupName, articles: articleCount }
        } else {
            const { groupName, proposedArticles } = rounderGroup;
            return { groupName, articles: proposedArticles }
        }
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        throw error;
    }
}

async function setProductGroups(productType, preferred, additional, selectedProductTypes, isDashboard, productGroupsMap) {
    try {
        const productGroups = {};
        additional.forEach(a => {
            const { groupName, proposedArticles, articlesPerDay } = a;
            if(productGroupsMap[groupName]) {
                productGroups[groupName] = { articles: proposedArticles }
                if (articlesPerDay)
                    productGroups[a.groupName].articlesPerDay = articlesPerDay
            }
        })
        const { articlesPerDay, proposedArticles } = preferred;
        productGroups[preferred.groupName] = { articles: proposedArticles, articlesPerDay }
        if (isDashboard && selectedProductTypes && selectedProductTypes[productType]) {
            const { articleCount } = selectedProductTypes[productType];
            // Ensure that article count at least matches the selected value or the minimum available value
            const minArticleCount = productGroups[selectedProductTypes[productType].groupName] ? productGroups[selectedProductTypes[productType].groupName] : articleCount;
            const articles = articleCount > productGroups[selectedProductTypes[productType].groupName] ? articleCount : minArticleCount;
            productGroups[selectedProductTypes[productType].groupName] = articles;
        }
        return productGroups;
    } catch (err) {
        throw err;
    }
}

export async function removeProduct(manifestBundles, productGroup) {
    try {
        const { productType } = productGroup;
        const shortName = bundleMap[productType];
        delete manifestBundles[shortName].productGroups[productType];
        manifestBundles[shortName].hasAll = false;
        ReactGA.event({
            category: 'ProductUIEvents',
            action: 'Remove Product',
            label: `${productGroup.brandName} ${productGroup.displayName}`,
        })
        const res = await setSubtotal(manifestBundles, shortName)
        if (res.manifestBundles)
            return { manifestBundles: res.manifestBundles }
        else
            throw res.error
    } catch (error) {
        Sentry.captureException(error);
        return { error };
    }
}

export async function changeBundleProductQuantity(manifestBundles, productGroup, value) {
    try {
        const quantity = parseInt(value);
        const { productType } = productGroup
        const shortName = bundleMap[productType];
        ReactGA.event({
            category: 'ProductUIEvents',
            action: productGroup.articles > quantity ? 'Decrement' : 'Increment',
            label: `${productGroup.brandName} ${productGroup.displayName}`,
            value: quantity
        })
        productGroup.articles = quantity;
        manifestBundles[shortName].productGroups[productType] = productGroup;
        const res = await setSubtotal(manifestBundles, shortName)
        if (res.manifestBundles)
            return { manifestBundles: res.manifestBundles }
        else
            throw res.error
    } catch (error) {
        Sentry.captureException(error);
        return { error }
    }
}

export function checkIfProductInBundle(manifestBundles, productGroup) {
    try {
        const { productType, groupName } = productGroup;
        const shortName = bundleMap[productType];
        return (manifestBundles[shortName].productGroups[productType] && manifestBundles[shortName].productGroups[productType].groupName === groupName);
    } catch (error) {
        Sentry.captureException(error);
        return false;
    }
}

export function removeBodyNoScroll() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('no-scroll');
}

export function removeAddProductModalNoScroll() {
    const addModal = document.getElementById("add-products-modal");
    addModal.classList.remove('no-scroll');
}

export function appendBodyNoScroll() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');
}

export function appendAddProductModalNoScroll() {
    const addModal = document.getElementById("add-products-modal");
    addModal.classList.add('no-scroll');
}

export function handleNewInterviewData(need, data, interview) {
    try {
        let trashType;
        switch (need) {
            case "needsKitchenFlow":
                const { dishwashing, housekeeping, cookingFrequency } = data;
                if (dishwashing) interview.dishwashing = dishwashing;
                if (housekeeping) interview.housekeeping = housekeeping;
                if (cookingFrequency) interview.cookingFrequency = cookingFrequency;
                break;
            case "needsKitchen":
            case "needsSmall":
            case "needsOutdoor":
            case "needsCompost":
            case "needsRecycling":
                trashType = need.replace("needs", "");
                break;
            case "needsLaundry":
                if (!interview.laundry) {
                    interview.laundry = { preferences: [] }
                }
                interview.laundry.frequency = data;
                break;
            default:
                break;
        }
        if (trashType) {
            const index = interview.trashPreferences.map(it => { return it.type }).indexOf(trashType);
            if (index > -1) {
                interview.trashPreferences.splice(index, 1);
            }
            interview.trashPreferences.push({
                type: trashType,
                frequency: data,
                bags: 1
            });
        }
        return { interview };
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        return { error };
    }
}


export function getBundleProducts(bundle, productGroupsMap, manifestBundles, rounderSet) {
    try {
        const productTypes = {};
        Object.entries(rounderSet).forEach(entry => {
            const productType = entry[0];
            const value = entry[1];
            if (!manifestBundles[bundle].productGroups[productType] && bundleMap[productType] === bundle) {
                const additional = value.additional.map(group => {
                    return productGroupsMap[group.groupName];
                })
                const preferred = (productGroupsMap[value.preferred.groupName]);
                productTypes[productType] = ({ additional, preferred });
            }
        })
        return productTypes;
    } catch (err) {
        console.error(err);
        Sentry.captureException(err);
        return [];
    }
}

export function createOrderFromManifestBundles(manifestBundles, rounderSet) {
    try {
        const order = {
            products: {}
        };
        Object.values(manifestBundles).forEach(bundle => {
            const { productGroups } = bundle;
            Object.values(productGroups).forEach(group => {
                const { articles, groupName, proposedArticles, productType } = group;
                if (articles !== null && !isNaN(articles)) {
                    order.products[groupName] = { articleCount: articles };

                    if (proposedArticles && articles !== proposedArticles) {
                        order.products[groupName].quantityChangeReason = 'NoReason';
                    }

                    if (rounderSet[productType] && rounderSet[productType].groupName !== groupName) {
                        order.products[groupName].productChangeReason = 'NoReason';
                    }
                }
            })
        });
        return order
    } catch (error) {
        throw error
    }
}
