import React, { Component, Fragment } from 'react';
import * as Sentry from '@sentry/browser';
import * as images from '../../assets';
import { validateZip, validateEmail, getLanding, getShipmentDate, isZipFromTexas } from '../../actions';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { MetaTags, Nav, ContinueButton, Loading } from '../Common';
import ReactGA from 'react-ga';
import { defaultZipcodes } from '../../data';
import { featureFlags } from '../../config';
import { OutageBanner } from '../Common/Alerts/OutageBanner';
const prod = (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT === 'production');

class CheckAvailability extends Component {
        constructor(props) {
            super(props);

            this.state = {
                isZipValid: true,
                isEmailValid: true,
                zipcode: "",
                email: ""
            };

            this.handleZipChange = this.handleZipChange.bind(this);
            this.onZipcodeBlur = this.onZipcodeBlur.bind(this);
            this.handleEmailChange = this.handleEmailChange.bind(this);
            this.validateEmail = this.validateEmail.bind(this);
            this.handleKeydown = this.handleKeydown.bind(this);
            this.next = this.next.bind(this);
            this.back = this.back.bind(this);
            this.handleRCChange = this.handleRCChange.bind(this);
            this.setSignUpData = this.setSignUpData.bind(this);
            this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
            this.continueInterview = this.continueInterview.bind(this);
        }

        componentWillMount() {
            window.addEventListener('keydown', this.handleKeydown, false);
        }

        componentWillUnmount() {
            window.removeEventListener('keydown', this.handleKeydown, false);
            clearInterval(this.setDeliveryDate)
        }

        handleKeydown(e) {
            if (e.keyCode && e.keyCode === 13) {
                this.next();
            }
        }

        componentDidMount() {
            if (prod) {
                window.rdt('track', 'ViewContent')
            }
            if (this.props.location && this.props.location.state && this.props.location.state.signUpData) {
                this.setSignUpData(this.props.location.state.signUpData)
            }
            else {
                this.setState({ signUpData: {} })
                this.props.getLanding(window.location.search);
            }
        }

        componentDidUpdate() {
            const { landingData, landingError, gettingLandingData } = this.props;
            const { awaitingZipcodes } = this.state;
            let { signUpData } = this.state;
            if (landingError && !this.state.landingError) {
                if(!signUpData)
                    signUpData = {};
                
                signUpData.zipcodes = defaultZipcodes;
                this.setState({ landingError, zipcodes: defaultZipcodes });
                Sentry.captureException(landingError);
            }
            if (gettingLandingData !== this.state.gettingLandingData) {
                this.setState({ gettingLandingData })
            }
            if (landingData && !this.state.landingData) {
                const { dropoffZipcodes, signupsOpen, coupon } = landingData;
                if (signUpData)
                    Object.assign(signUpData, { zipcodes: dropoffZipcodes, signupsOpen, coupon })
                else
                    signUpData = { zipcodes: dropoffZipcodes, signupsOpen, coupon };

                this.setState({ landingData, signUpData })
                if (awaitingZipcodes)
                    this.next(null, dropoffZipcodes);

                this.setState({ zipcodes: dropoffZipcodes, signupsOpen, coupon })
            }
        }

        setSignUpData(signUpData) {
            try {
                this.setState({ signUpData })
                this.setStateForKeyValue(signUpData);

                const { signupsOpen } = signUpData;

                if (signupsOpen === undefined) {
                    this.props.getLanding(window.location.search);
                }
            } catch (err) {
                console.error(err);
            }
        }

        setStateForKeyValue(data) {
            try {
                Object.entries(data).forEach(entry => {
                    const key = entry[0], value = entry[1];
                    if (value !== undefined) {
                        this.setState({ [key]: value })
                    }
                })
            } catch (err) {
                console.error(err);
            }
        }

        handleRCChange(value) {
            try {
                const rc = value.toLowerCase();
                this.setState({ rc, invalidReferral: false })
            } catch (err) {
                Sentry.captureException(err);
                console.error(err);
            }
        }

        handleZipChange(value) {
            if (!isNaN((parseInt(value)) && value.length < 6) || value === "") {
                this.setState({ zipcode: value, isZipValid: true })
            }
        }

        handleEmailChange(value) {
            this.setState({ email: value, isEmailValid: true })
        }

        onZipcodeBlur() {
            const { zipcode } = this.state;
            this.setState({ isZipValid: validateZip(zipcode) });
        }

        validateEmail() {
            const { email } = this.state;
            this.setState({ isEmailValid: validateEmail(email) });
        }

        renderLoading() {
            const { gettingShipment } = this.state;
            if (gettingShipment) {
                return (
                    <div style={{ marginTop: 320 }}>
                        <Loading />
                    </div>
                )
            }
        }

        back() {
            this.props.history.goBack();
        }

        next(e, zipcodes) {
            const {
                zipcode,
                approved,
                denied,
                rc,
                referral,
                // signupsOpen,
                signUpData
            } = this.state;

            const signupsOpen = !featureFlags.block_signups && this.state.signupsOpen;

            if (!zipcodes)
                zipcodes = this.state.zipcodes

            if (zipcodes) {
                if (zipcode && !approved && !denied) {
                    if (validateZip(zipcode) && (!featureFlags.block_outside_texas || isZipFromTexas(zipcode))) {
                        try {
                            const included = zipcodes.includes(zipcode);
                            if (included && signupsOpen) {
                                if (rc)
                                    signUpData.rc = rc;
                                signUpData.zipcode = zipcode
                                signUpData.approved = true;
                                ReactGA.event({
                                    category: 'UserInterviewEvents',
                                    action: 'Check Availability Success',
                                    label: `${zipcode}`,
                                })
                                this.continueInterview(signUpData)
                            } else if(featureFlags.isUngated) {
                                signUpData.zipcode = zipcode
                                signUpData.approved = true;
                                ReactGA.event({
                                    category: 'UserInterviewEvents',
                                    action: 'Check Availability Success - Ungated',
                                    label: `${zipcode}`,
                                })
                                this.continueInterview(signUpData)
                            } else {
                                ReactGA.event({
                                    category: 'UserInterviewEvents',
                                    action: `Check Availability Waitlist${signupsOpen ? "" : " Signups Closed"}`,
                                    label: `${zipcode}`,
                                })
                                this.setState({ denied: true })
                                this.props.history.push('/check-availability/waitlist', { signUpData })
                            }
                        } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                            this.setState({ denied: true })
                        }
                    } else if (featureFlags.block_outside_texas && !isZipFromTexas(zipcode)) {
                        this.setState({ denied: true });
                        this.props.history.push('/check-availability/waitlist', { signUpData })
                    } else {
                        this.setState({ isZipValid: false });
                    }
                } else if (approved) {
                    if (rc)
                        signUpData.rc = rc;

                    signUpData.zipcode = zipcode

                    this.continueInterview(signUpData)
                } else if (referral && rc === "sd4me") {
                    signUpData.rc = rc;
                    this.continueInterview(signUpData);
                } else if (referral && rc !== "sd4me") {
                    this.setState({ invalidReferral: true })
                }
            } else {
                this.props.getLanding();
                this.setState({ awaitingZipcodes: true, gettingShipment: true })
            }
        }

        continueInterview(signUpData) {
            const successPathname = signUpData.interviewPathname ? signUpData.interviewPathname : '/check-availability/success';
            this.props.getShipmentDate(signUpData.zipcode)
            this.props.history.push(successPathname, { signUpData })
        }

        renderSubheader() {
            const { gettingShipment } = this.state;
            const {
                zipcode,
                rc,
                isZipValid,
                referral,
                invalidReferral
            } = this.state;

            if (!gettingShipment) {
                if (referral) {
                    return (
                        <div>
                            <h2 className="gate-header">Enter referral code</h2>
                            <p className="interview-copy small"></p>
                            <input
                                autoFocus
                                autoComplete
                                name="code"
                                type="text"
                                className={`gate-input${invalidReferral ? " invalid" : ""}`}
                                placeholder="Enter your referral code"
                                onChange={(e) => this.handleRCChange(e.target.value)}
                                value={rc}
                            />
                            <div className="input-sublink-container">
                                <div onClick={() => this.setState({ referral: false })} className="input-sublink">Try zipcode instead?</div>
                            </div>
                            <p className="interview-copy small"><Alert variant="danger" className={invalidReferral ? "" : "hide"}>Sorry, the code you entered is not valid</Alert></p>
                            {this.renderValidationMessage()}
                        </div>
                    )
                } else {
                    return (
                        <div>
                            <h3 className="gate-header">Check availability</h3>
                            <p className="interview-copy small">See if Supply Drop is available in your area!</p>
                            <input
                                autoFocus
                                autoComplete="true"
                                type="number"
                                id="gate-zip-input"
                                className={`gate-input${isZipValid ? "" : " invalid"}`}
                                placeholder="ZIP Code"
                                name="zipcode"
                                onChange={(e) => this.handleZipChange(e.target.value)}
                                onBlur={this.onZipcodeBlur}
                                value={zipcode}
                                max="99999"
                            />
                            <div className="input-sublink-container text-center">
                                <div onClick={() => this.setState({ referral: true, zipcode: "", isZipValid: true })} className="input-sublink">Have a referral code?</div>
                            </div>
                            {this.renderValidationMessage()}
                        </div>
                    )
                }
            }
        }

        renderValidationMessage() {
            const { isZipValid, isEmailValid } = this.state;
            if (!isZipValid || !isEmailValid) {
                return (
                    <Alert variant="danger" className="alert-fixed-bottom">
                        Please enter a valid {isZipValid ? "email address" : "zipcode"}.
                    </Alert>
                )
            }
        }

        renderImg() {
            return <div className="interview-header-img"><img src={images["availability"]} alt="Availability" /></div>

        }

        renderContinue() {
            if (!this.state.gettingShipment) {
                return (
                    <ContinueButton next={this.next} text="Next" pressText="press" keyText="enter" />
                )
            }
        }

        render() {
            return (
                <Fragment>
                    <MetaTags title="Supply Drop - Check Availability" />
                    <OutageBanner />
                    <Nav pathname="/" back={this.back} />
                    <div className="interview-outer">
                        <div className="interview-inner fadeInRight animated-fast text-center">
                            {this.renderImg()}
                            {this.renderSubheader()}
                            {this.renderLoading()}
                            {this.renderContinue()}
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

const mapStateToProps = (state) => {
    const { landingData, gettingLandingData, landingError } = state.order;
    return { landingData, gettingLandingData, landingError };
}

export default connect(mapStateToProps, {
    getLanding,
    getShipmentDate
})(CheckAvailability);
