import styled, { css } from 'styled-components'
import {
    colors,
    mediaQueries,
    autoMarginLR
} from '.'

export const CustomerReferralContainer = styled.div`
    ${{...autoMarginLR }}
    margin-left: auto;
    margin-right: auto;
    max-width: 95vw;
    width: 920px;
    height: 320px;
    margin-top: 43px;
    background: ${colors.pureWhite};
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    box-shadow: 0px -15px 35px rgba(43,64,78,0.095505), 0px -5px 15px rgba(0,0,0,0.0490829);
    border-radius: 8px;
    padding: 34px 31px 34px 31px;
    ${mediaQueries("lg")`
        width: 500px;
        padding: 20px;
        margin-top: 20px;
        height: 345px;
    `};
    @media (max-width: 1199px) and (min-width: 767px) {
        height: 375px;
        padding-top: 20px;
    }
    @media (max-width: 320px) {
        height: 370px;
    }
`

export const CustomerReferralSubHeadline = styled.div`
    font-size: 18px;
    color: ${colors.bodyText};
    ${mediaQueries("lg")`
        font-size: 14px;
    `}
    @media (max-width: 1199px) and (min-width: 767px) {
        font-size: 18px;
    }
`

export const CustomerReferralImgContainer = styled.div`
    ${mediaQueries("lg")`
        max-width: 100%;
    `};
`

export const CustomerReferralTextContainer = styled.div`
    margin-left: 30px;
    margin-right: 75px;
    ${mediaQueries("lg")`
        margin-left: 10px;
        margin-right: 0px;
    `};
`

export const CustomerReferralTerms = styled.div`
    color: ${colors.bodyText};
    font-size: 14px;
    margin-top: 10px;
    ${mediaQueries('lg')`
        font-size: 12px;
        text-align: center;
    `}
`

export const CustomerReferralButton = styled.button`
    background: ${colors.btnBlue.idle};
    color: ${colors.contrastText};
    font-weight: bold;
    font-size: 16px;
    line-height: 20.08px;
    height: 40px;
    width: 91px;
    border-radius: 4px;
    margin-top: 25px;
    margin-left: 15px;
    & > a {
        color: ${colors.contrastText};
        &:hover {
            text-decoration: none;
        }
    }
    &:hover {
        background-color: ${colors.primary.dark};
    }
    &:active {
        background-color: ${colors.primary.active_contained};
    }
    ${mediaQueries("lg")`
        margin-top: 10px;
        width: 100%;
        text-align: center;
        margin-left: 0px;
    `};
`

export const CustomerReferralSocialContainer = styled.div`
    margin-top: 10px;
    ${mediaQueries("lg")`
        margin-left: 20px;
        margin-top: 20px;
        max-width: 65%;
    `};
`

export const DashboardHeader = styled.div`
    ${{ ...autoMarginLR }};
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: -0.01em;
    color: ${colors.titleText};
    width: 920px;
    max-width: 95%;
    margin-top: 40px;
    margin-bottom: 20px;
    ${props => props.recommendation && css`
        width: 560px;
    `}
    ${mediaQueries("md")`
        width: 500px;
        margin-top: 25px;
    `}
`