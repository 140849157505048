import React from 'react';
import * as images from '../../../assets';
import { InterviewQuestion } from '../../Common';
import { marginForCountdown } from '../../../actions';

class Bathrooms extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			bathrooms: 1,
			infoCopy: (
				<div>
					The amount of bathrooms you have helps us calculate what bathroom supplies you’ll need.
				</div>
			),
			image: {
				src: images.bathrooms,
				alt: "Bathrooms"
			}
		};

		this.increment = this.increment.bind(this);
		this.decrement = this.decrement.bind(this);
		this.next = this.next.bind(this);
		this.back = this.back.bind(this);
		this.setSignUpData = this.setSignUpData.bind(this);
		this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		if (this.props.location && this.props.location.state)
			this.setSignUpData(this.props.location.state.signUpData);
	}

	setSignUpData(signUpData) {
		try {
			this.setState({signUpData})
			this.setStateForKeyValue(signUpData);
			const { interview } = signUpData;
			if (interview) {
				this.setStateForKeyValue(interview);
				const { bathrooms } = interview;
				if (bathrooms)
					this.setState({ bathrooms })

				this.setState({ interview })
			} else {
				this.back();
			}

			if (signUpData.zipcode)
				marginForCountdown();
		} catch (err) {
			console.error(err);
		}
	}

	setStateForKeyValue(data) {
		try {
			Object.entries(data).forEach(entry => {
				const key = entry[0], value = entry[1];
				if (value !== undefined) {
					this.setState({ [key]: value })
				}
			})
		} catch (err) {
			console.error(err);
		}
	}

	next() {
		const {
			bathrooms,
			interview,
			signUpData
		} = this.state;

		interview.bathrooms = bathrooms;
		signUpData.interview = interview;

		this.props.history.push('/start/4', { signUpData });
	}

	decrement() {
		let { bathrooms } = this.state;
		if (bathrooms > 1) bathrooms -= 0.5;
		this.setState({ bathrooms })
	}

	increment() {
		let { bathrooms } = this.state;
		bathrooms > 0 ? bathrooms += 0.5 : bathrooms += 1;
		this.setState({ bathrooms })
	}

	back() {
		const { signUpData } = this.state;
		this.props.history.push('/start/2', { signUpData })
	}

	render() {
		const { image, infoCopy, bathrooms, signUpData } = this.state;
		const { isModal, history } = this.props
		const counter = {
			increment: this.increment,
			decrement: this.decrement,
			min: 1,
			count: bathrooms
		}
		return (
			<InterviewQuestion
				key="Bathrooms"
				id="Bathrooms"
				back={this.back}
				next={this.next}
				history={history}
				image={image}
				question="How many bathrooms do you have?"
				title="Supply Drop - Personalization - Household"
				isModal={isModal}
				counter={counter}
				infoCopy={infoCopy}
				zipcode={this.state.zipcode}
                setZipcode={(zipcode) => this.setState({ zipcode })}
				signUpData={signUpData}
			/>
		);
	}
}

export default Bathrooms;