import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { States } from './states';
import {
    Nav,
    MetaTags,
    FixedContinueBtn,
    OrderSummary,
    FormError,
    ValueProps,
    SummaryTestimonials
} from '../Common';
import GoogleAutocomplete from './GoogleMuiAutocomplete';
import { fetchAddresses } from '../../data';
import { ValuePropsHR as HR } from '../../Style';
import Countdown from '../Common/Connected/Countdown';
import * as Sentry from '@sentry/browser';
import { isValidPhoneNumber } from 'react-phone-number-input';
import StripeForm from './StripeForm';
import { Elements } from 'react-stripe-elements';
import * as images from '../../assets';
import {
    clearCreateOrderState,
    createOrder,
    formatPhone,
    getLanding,
    getTax,
    marginForCountdown,
    validateCityAndNames,
    validateEmail,
    validateStreetAddress,
    validateWorkEmail,
    validateZip,
    clearOrdersState,
    isZipFromTexas
    // formatDevEmail
} from '../../actions';
import { getGrandTotal, getSubtotalFromManifestBundles, formatTotalToGetTax } from '../../helpers/pricing'
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import Analytics from "../../analytics";
import { CheckoutBody as Body } from '../../Style';
import { defaultZipcodes, campaigns } from '../../data';
import { Logger } from '../../helpers/Logger';
import { featureFlags } from '../../config';
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import { Autocomplete } from '@material-ui/lab';
const logger = new Logger();
const prod = (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT === 'production');

const statesOptions = () => {
    const states = States.map(s => (
        <option key={s.iso} value={s.iso}>
            {s.name}
        </option>
    ));
    return states;
}

const statesMap = featureFlags.material_checkout_form ? States.map(s => { return s.iso }) : statesOptions;

class Checkout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                customer: {
                    firstName: {
                        name: "firstName",
                        type: "text",
                        label: "First Name",
                        placeholder: featureFlags.material_checkout_form ? "" : "Jane",
                        validation: validateCityAndNames,
                        isRequired: true
                    },
                    lastName: {
                        name: "lastName",
                        type: "text",
                        label: "Last Name",
                        placeholder: featureFlags.material_checkout_form ? "" : "Smith",
                        validation: validateCityAndNames,
                        isRequired: true
                    },
                },
                shipping: {
                    line1: {
                        name: "line1",
                        type: "text",
                        label: "Street Address 1",
                        placeholder: featureFlags.material_checkout_form ? "" : "Address",
                        validation: validateStreetAddress,
                        isRequired: true
                    },
                    line2: {
                        name: "line2",
                        type: "text",
                        label: "Suite/Apt",
                        placeholder: featureFlags.material_checkout_form ? "" : "Apt, suite, etc.",
                        width: 188
                    },
                    city: {
                        name: "city",
                        type: "text",
                        label: "City",
                        validation: validateCityAndNames,
                        placeholder: featureFlags.material_checkout_form ? "" : "City",
                        isRequired: true,
                        width: 184
                    },
                    state: {
                        name: "state",
                        type: "select",
                        label: "State",
                        options: statesMap,
                        placeholder: featureFlags.material_checkout_form ? "" : "State",
                        isRequired: true
                    },
                    zipcode: {
                        name: "zipcode",
                        type: "text",
                        label: "Zipcode",
                        isRequired: true,
                        placeholder: featureFlags.material_checkout_form ? "" : "Postal Code",
                        width: 168
                    }
                }
            },
            state: "",
        };

        this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateAll = this.validateAll.bind(this);
        this.validateLine2 = this.validateLine2.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.validateAndSetZip = this.validateAndSetZip.bind(this);
        this.validateState = this.validateState.bind(this);
        this.setStateCode = this.setStateCode.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.back = this.back.bind(this);
        this.next = this.next.bind(this);
        this.onContinueClick = this.onContinueClick.bind(this);
        this.setFromStripe = this.setFromStripe.bind(this);
        this.postTax = this.postTax.bind(this);
        this.setToEmployer = this.setToEmployer.bind(this);
        this.setToFetch = this.setToFetch.bind(this);
        this.setErrorMessage = this.setErrorMessage.bind(this);
    }

    componentDidMount() {
        if (prod) {
            window.fbq('track', 'InitiateCheckout')
            window.rdt('track', 'AddToCart')
        }
        Auth.configure({
            authenticationFlowType: 'USER_SRP_AUTH'
        });
        const { fields } = this.state;
        if (this.props.location && this.props.location.state)
            this.setSignUpData(this.props.location.state.signUpData);
        else {
            logger.error(new Error("Landed on checkout without signUpData"))
            this.props.history.push("/");
        }

        this.handleResize();

        if (!this.state.unauthedId) {
            Auth.currentCredentials().then(data => {
                this.setState({ unauthedId: data._identityId });
            }).catch(err => {
                Sentry.captureEvent(new Error('Auth.currentCredentials failed'))
                Sentry.captureException(err);
            })
        }

        fields.shipping.zipcode.validation = this.validateAndSetZip;
        fields.shipping.state.validation = this.validateState;
        fields.shipping.line2.validation = this.validateLine2;

        this.setState({ fields })

        this.refs.main.scrollIntoView();
    }

    componentDidUpdate() {
        const { order, customer, refreshTotals, signUpData, awaitingZipcodes, awaitingSource, source, stripeError, spaceTop, street_number, route, line1 } = this.state;
        const { tax, createOrderError, createOrderSuccess, landingData, landingError, creatingOrder } = this.props;
        if (tax !== undefined && tax !== this.state.tax) {
            this.setState({ tax, refreshTotals: true });
        }

        if (creatingOrder !== this.state.creatingOrder) {
            if (creatingOrder && !this.state.creatingOrder) {
                window.scrollTo(0, 0)
            }
            this.setState({ creatingOrder })
        }

        if (refreshTotals)
            this.setState({ refreshTotals: false })

        if (createOrderError && !this.state.createOrderError) {
            const message = createOrderError.response && createOrderError.response.data && typeof createOrderError.response.data === 'string' ? createOrderError.response.data : "An unexpected order has occured. Please ensure all fields are entered correctly, and try again."
            this.setState({ createOrderError: message, loading: false, creatingSource: false, awaitingSource: false });
            this.props.clearCreateOrderState();
        }
        if (createOrderSuccess && !this.state.createOrderSuccess && order.source && customer) {
            this.setState({ createOrderSuccess })
            const updatedSignUpData = Object.assign(signUpData, { order, customer, completedOrder: createOrderSuccess });
            this.props.history.push("/ordercomplete", { signUpData: updatedSignUpData })
        }

        if (landingData && !this.state.landingData) {
            const { dropoffZipcodes, signupsOpen, coupon } = landingData;
            Object.assign(signUpData, { dropoffZipcodes, signupsOpen, coupon })

            this.setState({ landingData: true, signUpData })

            this.setState({ zipcodes: dropoffZipcodes, signupsOpen, coupon })
        }
        if (this.state.zipcodes && awaitingZipcodes) {
            this.onContinueClick();
            this.setState({ awaitingZipcodes: false })
        }

        if (landingError && this.state.landingError) {
            this.setState({ zipcodes: defaultZipcodes })
        }
        if (awaitingSource && source) {
            this.setState({ awaitingSource: false })
            this.onContinueClick();
        }
        if (stripeError && awaitingSource)
            this.setState({ awaitingSource: false, loading: false })

        const element = document.getElementById("countdown-alert")
        if (element && spaceTop !== element.offsetHeight)
            this.setState({ spaceTop: element.offsetHeight })

        if (featureFlags.google_form && street_number && route && line1 !== (street_number + " " + route)) {
            this.setState({ line1: (street_number + " " + route) })
        }
    }

    async postTax(zipcode, state, manifestBundles, coupon, newCoupon, sdCredit) {
        const { subtotalCents, error } = getSubtotalFromManifestBundles(manifestBundles);
        if (!error && subtotalCents) {
            const res = getGrandTotal(subtotalCents, undefined, coupon, newCoupon, sdCredit);
            if (!res.error) {
                const total = formatTotalToGetTax(res.total);
                const { amount } = total;
                if (amount || amount === 0) {
                    this.props.getTax({
                        amount,
                        state,
                        postal_code: zipcode,
                    })
                } else {
                    if (total.error)
                        logger.error(total.error, new Error('unable to get tax on /checkout'), null, null, { subtotalCents, newCoupon, manifestBundles, total, sdCredit })
                    else
                        logger.error(new Error('formatTotalToGetTax() failing and not returning error'))
                }
            }
        } else {
            if (error)
                logger.error(error, new Error('getSubtotalFromManifestBundles() failed'))

            await this.props.clearOrdersState();
            this.setState({ tax: 0, refreshTotals: true })
        }

    }

    handleResize(e) {
        const { isMobile, isXS } = this.state;
        if (window.innerWidth < 992 && !isMobile) {
            this.setState({ isMobile: true })
        }
        if (window.innerWidth >= 991 && isMobile) {
            this.setState({ isMobile: false })
        }
        if (window.innerWidth < 551 && !isXS) {
            this.setState({ isXS: true })
        }
        if (window.innerWidth >= 551 && isXS) {
            this.setState({ isXS: false })
        }
    }

    handleInputChange(name, value) {
        const { userExistsError, workEmailError } = this.state;
        if ((name === "displayNumber" || name === "email") && userExistsError)
            this.setState({ userExistsError: false })

        if (name === "workEmail" && workEmailError)
            this.setState({ workEmailError: undefined })

        if (name === 'state')
            this.setStateCode(value)
        else
            this.setState({ [name]: value, [`${name}Invalid`]: false, [`${name}ErrorMessage`]: null, createOrderError: undefined })
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
        this.props.clearCreateOrderState();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
        if (this.props.creatingOrder) {
            this.props.clearCreateOrderState();
        }
    }

    validateField(name, value, validation) {
        try {
            const isValid = (value || (!value && name === "line2")) ? validation(value, true) : false;
            this.setState({ [`${name}Invalid`]: !isValid });
            if (!isValid) {
                this.setErrorMessage(name, value)
            }
            return isValid;
        }
        catch (err) {
            Sentry.captureException(err);
            console.error(err);
            return false;
        }
    }

    setErrorMessage(name, value) {
        const { fields } = this.state;
        let errorMessage, field;
        switch (name) {
            case "firstName":
            case "lastName":
                if (!value) {
                    errorMessage = fields.customer[name].label + " is required"
                } else {
                    errorMessage = fields.customer[name].label + " cannot include numbers or special characters"
                }
                break;
            case "line1":
            case "line2":
            case "city":
            case "state":
                if (statesMap.includes(value.toUpperCase()) && value.length === 2)
                    errorMessage = "We're currently only serving TX"
                else
                    errorMessage = "Please enter valid state"
                break;
            case "zipcode":
                field = fields.shipping[name].label;
                break;
            case "email":
                field = "Email"
                break;
            case "workEmail":
                field = "Work email"
                break;
            case "phone":
                field = "Phone";
                break;
            default:
                break;
        }

        if (field && !errorMessage) {
            if (!value) {
                errorMessage = field + " is required"
            } else {
                errorMessage = field + " format is invalid"
            }
        }
        this.setState({ [`${name}ErrorMessage`]: errorMessage })
    }

    setStateCode(value) {
        const { zipcode, zipcodeInvalid, manifestBundles, coupon, newCoupon, sdCredit } = this.state;
        const stateInvalid = !this.validateField('state', value, this.validateState)
        if (value && zipcode && !zipcodeInvalid && !stateInvalid && value !== this.state.state) {
            this.postTax(zipcode, value, manifestBundles, coupon, newCoupon, sdCredit)
        }
        this.setState({ state: value, stateInvalid });
    }

    validateLine2(value) {
        let { campaignShortName, line2Invalid, isFetch } = this.state;
        if (isFetch) {
            let formattedLine2 = "";
            if (!value.startsWith(campaignShortName)) {
                value = campaignShortName
            } else {
                formattedLine2 = value.split(campaignShortName)[1];
            }
            line2Invalid = !formattedLine2;
            this.setState({ line2: value, formattedLine2, line2Invalid })
            return !line2Invalid;
        } else {
            this.setState({ line2: value });
            return true
        }
    }

    validateAndSetZip(zipcode, isOnBlur) {
        const { state, manifestBundles, coupon, newCoupon, rc, sdCredit, stateInvalid } = this.state;
        let isValid = validateZip(zipcode);
        if (featureFlags.block_outside_texas && isValid && !rc) isValid = isZipFromTexas(zipcode);
        if (isValid) {
            if (isValid && state && !stateInvalid && isOnBlur) {
                this.postTax(zipcode, state, manifestBundles, coupon, newCoupon, sdCredit);
            }
        }
        this.setState({ zipcodeInvalid: !isValid, zipcode })
        return (isValid);
    }

    validateState(value) {
        const { rc } = this.state;
        if (!featureFlags.block_outside_texas || rc === "sd4me") {
            return !!value && statesMap.includes(value.toUpperCase());
        } else {
            const isZipUnsupported = value && value.toUpperCase() !== "TX";
            this.setState({ isZipUnsupported, isStateInvalid: !isZipUnsupported });
            return !isZipUnsupported;
        }
    }

    validatePhone(value) {
        const phone = formatPhone(value)
        this.setState({ phone })
        return isValidPhoneNumber(phone);
    }

    setSignUpData(signUpData) {
        try {
            this.setState({ signUpData })
            this.setStateForKeyValue(signUpData);
            const { zipcode, rc, coupon, newCoupon, manifestBundles, utm_content, utm_campaign, zipcodes, isEmployeePerk, sdCredit } = signUpData

            if (!zipcodes)
                this.props.getLanding()

            if (zipcode) {
                marginForCountdown();
                const firstTwo = zipcode.substring(0, 2);
                if (firstTwo === "78") this.setState({ state: "TX" })
                this.postTax(zipcode, "TX", manifestBundles, coupon, newCoupon, sdCredit)
            }
            if (utm_campaign) {
                if (utm_campaign.includes('fetch')) {
                    this.setToFetch(utm_content, manifestBundles, coupon, newCoupon);
                } else if (utm_campaign.includes('employer') || isEmployeePerk) {
                    this.setToEmployer(utm_content);
                }
            } else if (rc && rc.toLowerCase() === "sd4me") {
                this.setState({ rc });
            }
        } catch (err) {
            console.error(err);
        }
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    setToFetch(utm_content, manifestBundles, coupon, newCoupon) {
        const { shippingAddress } = fetchAddresses[utm_content];
        const { line1, line2, city, zipcode, state } = shippingAddress;
        this.postTax(zipcode, state, manifestBundles, coupon, newCoupon)
        this.setState({ line1, line2, city, zipcode, state, campaignShortName: utm_content.toUpperCase(), isFetch: true })
    }

    setToEmployer(utm_content) {
        const employerName = campaigns[utm_content].displayName;
        this.setState({ employerName, isEmployeePerk: true })
    }

    back() {
        const { signUpData } = this.state;
        const pathname = featureFlags.recommendation_page ? '/recommendation' : '/prediction';
        this.props.history.push(pathname, { signUpData })
    }

    next(customer) {
        const {
            order,
            coupon,
            interview,
            unauthedId,
            source,
            utm_campaign,
            rc,
            newCoupon
        } = this.state;

        interview.phone = customer.phone;
        customer.interview = interview;

        if (newCoupon) {
            order.coupon = newCoupon.code;
        } else if (coupon)
            order.coupon = coupon.code;

        if (utm_campaign) {
            customer.campaignCode = utm_campaign
        } else if (rc !== undefined) {
            customer.campaignCode = rc
        }

        order.customer = customer;
        order.source = source;
        this.props.createOrder(order, unauthedId);
        this.setState({ order });
    }

    onContinueClick() {
        const { source, creatingSource, isStripeFocused } = this.state;
        try {
            if (!source && (creatingSource || isStripeFocused)) {
                this.setState({ awaitingSource: true, loading: true })
            } else {
                this.setState({ loading: true })
                const isValid = this.validateAll();
                if (isValid) {
                    this.signUp();
                } else {
                    this.setState({ loading: false })
                }
            }
        } catch (error) {
            console.error(error);
            this.setState({ loading: false })
            Sentry.captureException(error);
        }
    }

    validateAll() {
        const {
            fields,
            source,
            zipcode,
            zipcodeInvalid,
            email,
            emailInvalid,
            workEmail,
            isZipUnsupported,
            userExistsError,
            isFetch,
            isEmployeePerk,
            employerSlug,
            line2,
            creatingSource
        } = this.state;
        let isValid = true;
        try {
            for (let i = 0; i < Object.values(fields).length; i++) {
                const set = Object.values(fields)[i];
                for (let j = 0; j < Object.entries(set).length; j++) {
                    const name = Object.entries(set)[j][0], value = Object.entries(set)[j][1];
                    const { isRequired, validation } = value;
                    if (isRequired) {
                        const fieldValid = this.validateField(name, this.state[name], validation)
                        if (!fieldValid)
                            isValid = false;
                    }
                }
            }
            if (isFetch) {
                const fetchValid = this.validateLine2(line2);
                if (!fetchValid)
                    isValid = false
            } else if (isEmployeePerk) {
                const res = validateWorkEmail(workEmail, employerSlug)
                const workEmailInvalid = !res.workEmailValid;
                if (workEmailInvalid) {
                    isValid = false;
                    const { workEmailError } = res;
                    this.setState({ workEmailInvalid, workEmailError })
                }
            }
            if (!source && !creatingSource) {
                this.setState({ stripeError: "Please provide a valid credit card number" });
                const ref = ReactDOM.findDOMNode(this.refs.stripe)
                window.scrollTo(0, ref.offsetTop - 60);
            } else if (creatingSource) {
                this.setState({ awaitingSource: true })
            }
            if (!source || !zipcode || zipcodeInvalid || !email || emailInvalid || isZipUnsupported || userExistsError) {
                isValid = false;
            }
            return isValid;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            return false;
        }
    }

    getRandomString() {
        const randomValues = new Uint8Array(30);
        const crypto = window.crypto || window.msCrypto;
        crypto.getRandomValues(randomValues);
        return Array.from(randomValues).map(this.intToHex).join('');
    }

    intToHex(num) {
        return num.toString(16).padStart(2, '0');
    }

    signUp() {
        const {
            email,
            phone,
            firstName,
            lastName,
            line1,
            state,
            zipcode,
            city,
            line2,
            deliveryInstructions,
            employer,
            isEmployeePerk,
            workEmail,
            refCust
        } = this.state;
        // const username = prod ? email.toLowerCase() : formatDevEmail(email);
        try {
            const password = this.getRandomString();
            Auth.signUp({
                // username,
                username: email.toLowerCase(),
                password,
                attributes: {
                    email: email.toLowerCase(),
                    phone_number: phone,
                    given_name: firstName,
                    family_name: lastName
                }
            }).then(data => {
                Auth.signIn(email.toLowerCase(), password).then(() => {
                    const customer = {
                        email: email.toLowerCase(),
                        firstName,
                        lastName,
                        phone,
                        shipping: {
                            name: `${firstName} ${lastName}`,
                            address: line1,
                            state,
                            zipcode,
                            city,
                        }
                    };
                    if (line2) customer.shipping.address += `, ${line2}`;
                    if (deliveryInstructions) customer.shipping.deliveryInstructions = deliveryInstructions;
                    if (isEmployeePerk && employer) {
                        customer.workEmail = workEmail;
                        customer.employerId = employer.employerId;
                    }
                    if (refCust) {
                        customer.referringCustomerId = refCust
                    }
                    Auth.currentAuthenticatedUser().then(data => {
                        Analytics.setUser(data.username, { email: email.toLowerCase() });
                    }).catch(err => {
                        Sentry.captureException(err);
                    })
                    this.setState({ customer })
                    this.next(customer);
                }).catch(signInErr => {
                    Sentry.captureException(signInErr)
                    this.setState({ userExistsError: "We are not currently able to process your order", loading: false })
                })
            }).catch(err => {
                console.error(JSON.stringify(err));
                this.setState({ userExistsError: "A user with that phone number or email address already exists", loading: false })
                const emailRef = ReactDOM.findDOMNode(this.refs.email)
                window.scrollTo(0, emailRef.offsetTop - 60);
                Sentry.captureException(err);
            })
        } catch (error) {
            console.error(JSON.stringify(error))
            Sentry.captureException(error);
            // Error with the SD code, not the amplify code...
            this.setState({ userExistsError: "A user with that phone number or email address already exists", loading: false })
            const emailInputRef = ReactDOM.findDOMNode(this.refs.email - 60)
            window.scrollTo(0, emailInputRef.offsetTop);
        }
    }

    renderMobileValueProps() {
        const { isMobile } = this.state;
        if (isMobile) {
            return (
                <Fragment>
                    <HR />
                    <ValueProps />
                    <SummaryTestimonials isMobile={isMobile} />
                </Fragment>
            )
        }
    }

    renderCountdown() {
        return <Countdown history={this.props.history} zipcode={this.state.zipcode} setZipcode={(zipcode) => this.setState({ zipcode })} signUpData={this.state.signUpData} />
    }

    renderCustomerForm() {
        const { fields } = this.state;
        const { customer } = fields;
        return (
            <div className="checkout-section">
                {this.renderInputs(customer)}
                {this.renderEmail()}
                {this.renderPhoneNumber()}
                {this.renderWorkEmail()}
                {this.renderAccountExistsError()}
                {this.renderEmployeePerkDisclaimer()}
                {this.renderTextMessageDisclaimer()}
            </div>
        )
    }

    renderBillingForm() {
        return (
            <div className="input-wrapper flex-row flex-wrap" ref="stripe">
                <Elements>
                    <StripeForm
                        setParentState={this.setFromStripe}
                        handleInputChange={this.handleInputChange}
                        validateCardName={this.validateField}
                        isXS={this.state.isXS}
                    />
                </Elements>
                {this.renderStripeError()}
            </div>
        )
    }

    setFromStripe(stripe) {
        const { source, error, creatingSource, isStripeFocused } = stripe;
        this.setState({ source, stripeError: error, creatingSource, isStripeFocused });
    }

    renderShippingForm() {
        const { fields, isFetch } = this.state;
        if (isFetch) {
            return this.renderFetchFields();
        } else {
            const { shipping } = fields;
            const rows = this.renderInputs(shipping);
            return (
                <div className="checkout-section">
                    {rows}
                    {this.renderDeliveryInstructions()}
                </div>
            )
        }
    }

    renderDeliveryInstructions() {
        const { isXS, deliveryInstructions } = this.state;
        if (featureFlags.material_checkout_form) {
            return (
                <TextField
                    type="text"
                    name="delivery-instructions"
                    id="checkout-delivery-instructions"
                    multiline
                    fullWidth={isXS}
                    style={{
                        margin: isXS ? "1%" : 4,
                        width: isXS ? "98%" : 456,
                        maxWidth: "100%"
                    }}
                    label="Delivery Instructions"
                    placeholder="Gate code is 123, leave on the porch."
                    value={deliveryInstructions}
                    onChange={e => this.handleInputChange('deliveryInstructions', e.currentTarget.value)}
                    variant="outlined"
                />
            )
        } else {
            return (
                <Fragment>
                    <FormError copy={this.state.isZipUnsupported ? "Supply Drop does not currently deliver to your area" : null} />
                    <div className="modal-input-container">
                        <label className="modal-label">Delivery Instructions</label>
                    </div>
                    <textarea
                        className="modal-input"
                        type="text"
                        name="delivery-instructions"
                        id="delivery-instructions"
                        placeholder="Delivery instructions"
                        value={deliveryInstructions}
                        onChange={e => this.handleInputChange('deliveryInstructions', e.currentTarget.value)}
                    />
                </Fragment>
            )
        }
    }

    renderFetchFields() {
        const { line2Invalid, firstName, lastName, city, state, zipcode, line1, line2, isXS } = this.state;
        return (
            <Fragment>
                <div className="checkout-input-container half space-top">
                    <TextField
                        error={line2Invalid}
                        helperText={line2Invalid && "Invalid Fetch code"}
                        type="text"
                        id="fetch-code"
                        name="fetch-code"
                        label="Fetch Code"
                        value={line2}
                        style={{
                            width: isXS ? "100%" : 224
                        }}
                        onChange={e => this.validateLine2(e.currentTarget.value)}
                        onBlur={e => this.validateLine2(e.currentTarget.value)}
                        variant="outlined"
                    />
                </div>
                <div className="checkout-confirm-details space-top">
                    <p>
                        <u>Delivery Address</u><br /><br />
                        {firstName} {lastName}<br />
                        {line1}<br />
                        {line2}<br />
                        {city}, {state} {zipcode}<br /><br />
                    </p>
                </div>
            </Fragment>
        )
    }

    renderTextMessageDisclaimer() {
        return (
            <p className="mobile-note space-top">
                *You will receive occasional <b>text messages</b> from us to help tune your orders. Unsubscribe at any time (although that will limit the magic of our service).
            </p>
        )
    }

    renderEmployeePerkDisclaimer() {
        const { employer, isEmployeePerk } = this.state;

        if (isEmployeePerk && employer) {
            const { displayName } = employer;
            return (
                <p className="mobile-note space-top">
                    By clicking Place Order you confirm you are currently employed by {displayName} and that at any time you are no longer employed by {displayName} or {displayName} no longer supplements your Supply Drop subscription, that you will be responsible for the entire cost of your plan.
                </p>
            )
        }
    }

    renderMaterialInput(id, values) {
        const { isXS } = this.state;
        const { type, label, validation, options, name, ref } = values;
        let width = values.width;
        if (isXS) {
            width = "100%"
        } else if (!width) {
            width = 224
        }
        let input;
        const isInvalid = this.state[`${id}Invalid`];
        const errorMessage = this.state[`${id}ErrorMessage`];
        if (featureFlags.google_form && id === "line1") {
            return (
                <div className="checkout-input-container">
                    <GoogleAutocomplete
                        key={id}
                        id={id}
                        setValue={this.handleInputChange}
                        error={isInvalid}
                        label={label}
                        isXS={isXS}
                    />
                </div>
            )
        } else if (type !== "select") {
            const halfSize = id !== "line1" && id !== "firstName" && id !== "lastName" && id !== "email";
            input = (
                <div key={id} className={`checkout-input-container${halfSize ? " half" : ""}`}>
                    <TextField
                        key={id}
                        error={isInvalid}
                        helperText={isInvalid && errorMessage}
                        type={type}
                        id={id}
                        autoComplete="on"
                        name={name}
                        label={label}
                        inputProps={{
                            maxLength: id === 'firstName' || id === 'lastName' ? "30" : null,
                        }}
                        style={{
                            width
                        }}
                        value={this.state[id] || ''}
                        onChange={(e) => this.handleInputChange(id, e.target.value)}
                        onBlur={(e) => this.validateField(id, e.target.value, validation)}
                        ref={ref ? ref : null}
                        variant="outlined"
                    />
                </div>
            )
        } else {
            input = (
                <div key={id} className="checkout-input-container half">
                    <FormControl variant="outlined" style={{ width: isXS ? "100%" : "88px" }}>
                        <Autocomplete
                            id="state"
                            selectOnFocus
                            disableClearable
                            value={this.state[id] || ''}
                            inputValue={this.state[id] || ''}
                            onInputChange={(event, newInputValue) => {
                                this.setStateCode(newInputValue);
                            }}
                            name="state"
                            options={options}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="State"
                                    error={isInvalid}
                                    helperText={isInvalid && errorMessage}
                                />
                            )}
                        >
                        </Autocomplete>
                    </FormControl>
                </div>
            )
        }
        return input
    }

    renderClassicInput(id, values) {
        const { type, label, validation, placeholder, name, ref, options } = values
        if (id !== "state") {
            return (
                <div key={label} className="modal-input-container">
                    <label className="modal-label">{label}</label>
                    <input
                        autoFocus={id === 'firstName'}
                        key={id}
                        className={`modal-input${this.state[`${id}Invalid`] ? ' invalid' : ''}`}
                        type={type}
                        id={id}
                        autoComplete="on"
                        name={name}
                        placeholder={placeholder}
                        value={this.state[id] || ''}
                        onChange={(e) => this.handleInputChange(id, e.target.value)}
                        onBlur={(e) => this.validateField(id, e.target.value, validation)}
                        ref={ref ? ref : null}
                    />
                </div>
            )
        } else {
            return (
                <div key={label} className="modal-input-container">
                    <label className="modal-label">{label}</label>
                    <select
                        name="state"
                        id="state"
                        className={`modal-input${this.state[`${id}Invalid`] ? ' invalid' : ''}`}
                        value={this.state[id]}
                        onChange={e => this.setStateCode(e.currentTarget.value)}
                    >
                        {options()}
                    </select>
                </div>
            )
        }
    }

    renderInputs(fields) {
        try {
            return Object.entries(fields).map(entry => {
                const id = entry[0], values = entry[1];
                if (featureFlags.material_checkout_form) {
                    return this.renderMaterialInput(id, values)
                } else {
                    return this.renderClassicInput(id, values)
                }
            })
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            return [];
        }
    }

    renderEmail() {
        const { isEmployeePerk, employer } = this.state;
        const email = {
            'email': {
                type: "text",
                label: isEmployeePerk && employer ? "Personal Email" : "Email",
                name: "email",
                validation: validateEmail,
                placeholder: featureFlags.material_checkout_form ? "" : "jane@smith.com",
                isRequired: true,
                ref: "email",
                width: 260
            }
        }
        return this.renderInputs(email);
    }

    renderWorkEmail() {
        const { isEmployeePerk } = this.state;
        if (isEmployeePerk) {
            const workEmail = {
                workEmail: {
                    type: "text",
                    label: "Work Email",
                    name: "work email",
                    validation: validateEmail,
                    isRequired: true,
                    ref: "workEmail",
                    width: 260
                }
            }
            return (
                <Fragment>
                    {this.renderInputs(workEmail)}
                    {this.renderWorkEmailError()}
                </Fragment>
            )
        }
    }

    renderPhoneNumber() {
        const { phoneInvalid, isXS, phoneErrorMessage } = this.state;
        if (featureFlags.material_checkout_form) {
            return (
                <div className="checkout-input-container">
                    <TextField
                        autoComplete="home tel-national"
                        error={phoneInvalid}
                        helperText={phoneInvalid && phoneErrorMessage}
                        id="phone"
                        label="Mobile Phone"
                        type="tel"
                        style={{ width: isXS ? "100%" : 188 }}
                        onChange={(e) => this.handleInputChange('displayNumber', e.target.value)}
                        onBlur={(e) => this.validateField('phone', e.target.value, this.validatePhone)}
                        variant="outlined"
                    />
                </div>
            )
        } else {
            return (
                <div className="modal-input-container">
                    <label className="modal-label">Mobile Number</label>
                    <input
                        autoComplete="home tel-national"
                        className={`modal-input${phoneInvalid ? ' invalid' : ''}`}
                        placeholder="(512) 555-1234"
                        id="phone"
                        type="tel"
                        onChange={(e) => this.handleInputChange('displayNumber', e.target.value)}
                        onBlur={(e) => this.validateField('phone', e.target.value, this.validatePhone)}
                    />
                </div>
            )
        }
    }

    renderDeliveryHeader() {
        const { isFetch } = this.state;
        if (isFetch) {
            return (
                <Fragment>
                    <div className="checkout-stage-container">
                        <div className="checkout-stage-number">1</div>
                        <span id="fetch-shipping-logo"><img src={images.fetch_logo} alt="Fetch" /></span><span className="checkout-stage-name">Delivery Info</span>
                    </div>
                    <hr className={`checkout-hr${featureFlags.material_checkout_form && " sd-mui-checkout"}`} />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div className="checkout-stage-container">
                        <div className="checkout-stage-number">1</div>
                        <span className="checkout-stage-name">Deliver To</span>
                    </div>
                    <hr className="checkout-hr" />
                </Fragment>
            )
        }
    }

    renderBillingHeader() {
        return (
            <Fragment>
                <div className="checkout-stage-container">
                    <div className="checkout-stage-number">2</div>
                    <span className="checkout-stage-name">Billing</span>
                </div>
                <hr className="checkout-hr" />
            </Fragment>
        )
    }

    renderMain() {
        const {
            manifestBundles,
            coupon,
            isEmployeePerk,
            newCoupon,
            sdCredit,
            isMobile,
            creatingSource,
            loading,
            tax,
            refreshTotals,
            awaitingSource,
            refCust,
            spaceTop
        } = this.state;

        const { creatingOrder } = this.props;
        if (manifestBundles && !creatingOrder) {
            return (
                <Fragment>
                    {this.renderCountdown()}
                    <Nav spaceTop={spaceTop} back={this.back} />
                    <Body>
                        <div className="checkout-container">
                            <div className="checkout-main">
                                {this.renderDeliveryHeader()}
                                {this.renderCustomerForm()}
                                {this.renderShippingForm()}
                                {this.renderBillingHeader()}
                                {this.renderBillingForm()}
                            </div>
                        </div>
                        {this.renderDesktopOrderSummary()}
                    </Body>
                    <FixedContinueBtn
                        text="Place Order"
                        showTerms={true}
                        showCustomerRef={!!refCust}
                        tax={tax}
                        loading={creatingSource || loading}
                        disabled={loading || awaitingSource}
                        manifestBundles={manifestBundles}
                        next={this.onContinueClick}
                        coupon={coupon}
                        newCoupon={newCoupon}
                        sdCredit={sdCredit}
                        isEmployeePerk={isEmployeePerk}
                        isMobile={isMobile}
                        refreshTotals={refreshTotals}
                        showReferral={refCust ? true : false}
                    />
                </Fragment>
            )
        } else if (creatingOrder) {

            return (
                <Fragment>
                    <MetaTags title="Supply Drop - Checkout" />
                    <Nav />
                    <Body>
                        <div className="checkout-container">
                            <div className="checkout-main">
                                <div className="text-center">
                                    <img src={images.predicting} className="pulse animated-super-slow infinite delay1" alt="Loading" />
                                </div>
                                <div className="interview-copy">
                                    <b>Processing your order...</b><br />
                                    Please do not close or navigate away from window.
                                </div>
                            </div>
                        </div>
                        {this.renderDesktopOrderSummary()}
                    </Body>
                    <FixedContinueBtn
                        text="Place Order"
                        loading={true}
                        disabled={true}
                        manifestBundles={manifestBundles}
                        next={this.onContinueClick}
                        coupon={coupon}
                        newCoupon={newCoupon}
                        sdCredit={sdCredit}
                        isEmployeePerk={isEmployeePerk}
                        isMobile={isMobile}
                    />
                </Fragment>
            )
        }
    }

    renderDesktopOrderSummary() {
        const {
            isMobile,
            manifestBundles,
            coupon,
            isEmployeePerk,
            employer,
            tax,
            newCoupon,
            sdCredit,
            refCust
        } = this.state;
        if (!isMobile) {
            return (
                <OrderSummary
                    manifestBundles={manifestBundles}
                    coupon={coupon}
                    newCoupon={newCoupon}
                    sdCredit={sdCredit}
                    isEmployeePerk={isEmployeePerk}
                    employer={employer}
                    tax={tax}
                    showValueProps={!isMobile}
                    showReferral={refCust ? true : false}
                />
            )
        }
    }

    renderStripeError() {
        return <FormError copy={this.state.stripeError} />
    }

    renderWorkEmailError() {
        return <FormError copy={this.state.workEmailError} />
    }

    renderAccountExistsError() {
        if (this.state.userExistsError)
            return <FormError copy={this.state.userExistsError} />
    }

    renderOrderError() {
        const { createOrderError } = this.state;
        if (createOrderError) {
            return <FormError copy={createOrderError} />
        }
    }

    render() {
        return (
            <Fragment>
                <MetaTags title="Supply Drop - Checkout" />
                <div ref="main" />
                <div className="has-fixed-continue with-terms">
                    {this.renderMain()}
                    {this.renderMobileValueProps()}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { getTaxError, tax, createOrderSuccess, creatingOrder, createOrderError, landingData, gettingLandingData, landingError, shipment } = state.order;
    return { tax, getTaxError, createOrderSuccess, creatingOrder, createOrderError, landingData, gettingLandingData, landingError, shipment };
}

export default connect(mapStateToProps, {
    getTax,
    clearCreateOrderState,
    clearOrdersState,
    createOrder,
    getLanding
})(Checkout);
