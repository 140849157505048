import React, { Component, Fragment } from 'react';
import * as Sentry from '@sentry/browser';
import { featureFlags } from '../../config';
import {
    CardElement,
    injectStripe
} from 'react-stripe-elements';
import StripeInput from './StripeInput';
import { TextField } from '@material-ui/core'

class StripeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.createSource = this.createSource.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    handleFocus() {
        this.props.setParentState({ isStripeFocused: true })
    }

    async createSource() {
        this.props.setParentState({ creatingSource: true })
        try {
            const stripeRes = await this.props.stripe.createSource({
                type: 'card'
            })
            const { source, error } = stripeRes;
            if (source) {
                this.props.setParentState({ source, creatingSource: false });
            } else if (error) {
                this.setState({ error: error.message });
                this.props.setParentState({ error: error.message, creatingSource: false });
            }
        } catch (error) {
            console.error(error);
            Sentry.captureException(error)
        }
    }

    renderForm() {
        if (featureFlags.material_stripe_form && featureFlags.material_checkout_form) {
            return (
                <TextField
                    name="ccnumber"
                    variant="outlined"
                    style={{width: this.props.isXS ? "100%" : 456}}
                    onBlur={this.createSource}
                    onFocus={this.handleFocus}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        inputComponent: StripeInput,
                        inputProps: {
                            component: CardElement
                        },
                    }}
                />
            )
        } else {
            return (
                <Fragment>
                    <label className="modal-label">Credit Card</label>
                    <CardElement className="stripe-input" onBlur={this.createSource} onFocus={this.handleFocus} />
                </Fragment>
            )
        }
    }

    render() {
        return (
            <div className="modal-input-container full">
                {this.renderForm()}
            </div>
        )
    }
}

export default injectStripe(StripeForm)
